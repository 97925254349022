import React from "react";
import secureLocalStorage from "react-secure-storage";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Layouts/Sidebar";

const PrivateComponent = () => {
  const auth = JSON.parse(secureLocalStorage.getItem("adminInfo"));
  console.log("auth", auth);
  return auth ? (
    <>
      <Sidebar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateComponent;

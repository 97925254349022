import requests from "./httpService";

const HolidayService = {
  getHolidays(id, body) {
    return requests.get(`/holidays/all?company_id=${id}`, body);
  },
  createHolidays(body) {
    return requests.post(`/holidays/create`, body);
  },
  updateHolidays(id, body) {
    return requests.post(`/holidays/update/${id}`, body);
  },
  statusUpdate(id, body) {
    return requests.post(`/holidays/statusupdate/${id}`, body);
  },
  deleteHoliday(id, body) {
    return requests.delete(`/holidays/${id}`, body);
  },
};

export default HolidayService;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const StatusBadgeHr = (props) => {
  const { item, api } = props;
  const [itemStatus, setItemStatus] = useState(item.status);

  const UpdateStatus = (e) => {
    e.preventDefault();
    setItemStatus(e.target.value);
    if (e.target.value === "1") {
      alert("Once Approved, You can't change");
    }
    if (e.target.value === "2") {
      alert("Once Dis Approved, You can't change");
    }
    api(item.id, e.target.value);
  };

  return (
    <>
      <select
        value={itemStatus}
        onChange={(e) => UpdateStatus(e)}
        className=" form-control sr selectset"
        disabled={itemStatus == 1 || itemStatus == 2}
      >
        <option value="0">Pending</option>
        <option value="1">Approved</option>
        <option value="2">Dis Approved</option>
      </select>

      {/* <Link to="#" onClick={(e) => UpdateStatus(e, item)}>
        {itemStatus === true ? (
          <span className="btn btn-success waves-effect waves-light btn-sm">
            Yes
          </span>
        ) : (
          <span className="btn btn-danger waves-effect waves-light btn-sm">
            No
          </span>
        )}
      </Link> */}
    </>
  );
};

export default StatusBadgeHr;

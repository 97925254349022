import React from "react";
import { Link } from "react-router-dom";
import UserProfile from "./UserProfile.";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserTable = (props) => {
  const {
    username,
    handelSearch,
    handelReset,
    isLoading,
    Users,
    EmployeDetails,
    GotoUser,
    handelCheckAttendance,
    handelViewUser,
  } = props;
  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex mb-3">
          <input
            value={username}
            onChange={(e) => handelSearch(e)}
            className="form-control "
            type="search"
            placeholder="Search Employee Name"
          />
          <button
            onClick={(e) => {
              handelReset(e);
            }}
            style={{ marginLeft: "10px" }}
            className="btn btn-color"
          >
            Reset
          </button>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0 table-striped">
                <thead>
                  <tr className="tableHeaderbg">
                    <th>Sr#</th>
                    <th>Employee Id</th>
                    <th>Employee Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                        <td>
                          <Skeleton width="40%" />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {Users.map((es, index) => (
                        <tr key={es.id}>
                          <td className="tableset">{index + 1}</td>
                          <td className="tableset">{es.id}</td>
                          <td className="tableset">
                            <UserProfile
                              username={es?.first_name + " " + es.last_name}
                              userImage={es?.image}
                            />
                            &nbsp;{es?.first_name + " " + es.last_name}
                          </td>
                          <td className="tableset">
                            <Link
                              to="#"
                              data-bs-toggle="tooltip1"
                              data-bs-placement="top"
                              title="User Details"
                              onClick={(e) => EmployeDetails(e, es.id)}
                            >
                              <i className="uil-user iconscolor" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip1"
                              data-bs-placement="top"
                              title="Leave Request"
                              onClick={(e) => GotoUser(e, es.id)}
                            >
                              <i className="uil-file-landscape-alt iconscolor" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip1"
                              data-bs-placement="top"
                              title="Check Attendance"
                              onClick={(e) => handelCheckAttendance(e, es)}
                            >
                              <i className="uil-book-reader iconscolor" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Paid/Unpaid"
                              onClick={(e) => handelViewUser(e, es.id)}
                            >
                              <i className="uil-moneybag iconscolor" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTable;

import React, { useEffect, useState } from "react";
import LeadsServices from "../../Services/LeadsServices";
import DataParser from "../../utlits/Dataparser";

const SelectFiled = (props) => {
  const { item, reFreshData } = props;
  const [Source, setSource] = useState("");

  useEffect(() => {
    setSource(item?.lead_source);
  }, [item]);

  const handelUpdate = (e) => {
    e.preventDefault();
    setSource(e.target.value);
    const id = item.id;
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();
    const dataLead = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      source: e.target.value,
    };
    LeadsServices.updateLeadSource(id, dataLead).then((res) => reFreshData(e));
  };
  return (
    <select
      className=" form-control sr selectset"
      value={Source}
      onChange={(e) => handelUpdate(e)}
    >
      <option value="Google">Google</option>
      <option value="Facebook">Facebook</option>
      <option value="HFEC">HFEC</option>
      <option value="SMS">SMS</option>
      <option value="WHMCS">WHMCS</option>
    </select>
  );
};

export default SelectFiled;

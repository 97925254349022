import React, { useState } from "react";
import DataParser from "../../utlits/Dataparser";
import moment from "moment";
import { DatePicker } from "antd";
import { useEffect } from "react";

const DateField = (props) => {
  const { item, Services, data, defaultDate } = props;

  const [date, setDate] = useState("");

  const dateFormat = "DD-MM-YYYY";

  const onDateChange = (date, dateString) => {
    const newDate = moment(dateString).format(dateFormat);
    setDate(newDate);
    const id = item.id;
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();
    const dateF = moment(date, "YYYY-MM-DD");

    const dateSent = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      duedate: dateF,
    };

    Services(id, dateSent).then((res) => {
      if (res.Good) {
        data();
      } else {
      }
    });
  };
  return (
    <>
      <DatePicker
        disabledDate={(current) => {
          let customDate = moment().format("YYYY-MM-DD");
          return current && current < moment(customDate, "YYYY-MM-DD");
        }}
        allowClear={false}
        bordered={false}
        defaultValue={moment(defaultDate)}
        onChange={onDateChange}
        format={dateFormat}
      />
    </>
  );
};

export default DateField;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PolicyServices from "../../../Services/PolicyServices";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const Policy = () => {
  const navigate = useNavigate();
  const compid = DataParser.CompanyId();
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    getAllPolicies();
  }, []);

  const getAllPolicies = () => {
    PolicyServices.getHRPolicy(compid).then((res) => {
      const data = res.data.HrPolicies;
      setPolicies(data);
    });
  };

  const handleOpenUpadte = (e, id) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/policy/${id}`);
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">HR Policy</h4>
                  <div className="page-title-right">
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/policy/create`}
                    >
                      <span
                        type="button"
                        className="btn btnsss waves-effect waves-light"
                      >
                        Add Policy
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <table id="dataEable" className="table mb-0 table-striped">
                      <thead>
                        <tr className="tableHeaderbg">
                          <th>ID</th>
                          <th>Title</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {policies?.map((es, index) => (
                          <tr
                            className={index % 2 === 0 ? "odd" : "even"}
                            key={index}
                          >
                            <td className="tableset">{es.id}</td>
                            <td className="tableset">{es.name}</td>
                            <td className="tableset">
                              <Link
                                to="#"
                                onClick={(e) => handleOpenUpadte(e, es.id)}
                                className="invoice-action-edit cursor-pointer"
                              >
                                <i
                                  className="uil-pen iconscolor"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editCategoryModal"
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;

import React, { useState } from "react";
import VacancyService from "../../../../Services/VacancyService";
import Performa from "./Performa";
import PerformaCheck from "./PerformaCheck";

const Interviewed = ({ applicatns, getData, eidss }) => {
  const [domainKnowlege, setDomainKnowlege] = useState("");
  const [behavoiur, setBehavoiur] = useState("");
  const [modalId, setModalId] = useState(null);
  const [viewData, setViewData] = useState([]);

  const handelView = (e,value)=>{
    e.preventDefault();
    setViewData(value);
  }

  const handelStatus = (e, item, status) => {
    e.preventDefault();
    console.log("item:", item);
    VacancyService.updateVacancyApplicantsStatus(item, status).then((res) => {
      console.log("res:", res);
      getData(eidss);
    });
  };
  const handelSubmit = (e, newdata) => {
    e.preventDefault();
    const link = document.getElementById("closeButton");
    let bdata = {
      performa: newdata,
    };
    VacancyService.VacancyApplicantsPerforma(modalId, bdata).then((res) => {
      link.click();
      console.log("res:", res);
      getData(eidss);
    });
  };
  return (
    <>
      <div className="col-lg-12">
        <Performa
          handelSubmit={handelSubmit}
          offcanvasid={"offcanvasRightCreate"}
          labelledby={"offcanvasRightLabel"}
        />
         <PerformaCheck
        viewData={viewData}
        offcanvasid={"offcanvasRightCreate1"}
        labelledby={"offcanvasRightLabel1"}
      />
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <table className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Applicant's Name</th>
                        <th>Apply for</th>
                        <th>Applied Location</th>
                        <th>Email</th>
                        <th>CV</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicatns.map((es) => (
                        <>
                          {es.applicants
                            .filter((es) => es.status === "interview")
                            .map((ess) => (
                              <tr>
                                <th scope="row">
                                  {ess.first_name}
                                  {ess.first_name}
                                </th>
                                <td className="tableset">{es.title}</td>
                                <td className="tableset">{ess.location}</td>
                                <td className="tableset">{ess.email}</td>

                                <td className="tableset">
                                  <a
                                    target="_blank"
                                    href={`${process.env.REACT_APP_CV_PATH}/${ess.cv}`}
                                  >
                                    <i className="uil-eye iconscolor" />
                                  </a>
                                </td>
                                <td className="tableset">
                                  {ess.interviewed === 1 ? (
                                    <button
                                    onClick={(e)=>handelView(e,ess.performa)}
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasRightCreate1"
                                      aria-controls="offcanvasRight1"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#createLeaveTypeModal"
                                      className="btn btnclr"
                                    >
                                      Check Performa
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) => setModalId(ess.id)}
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasRightCreate"
                                      aria-controls="offcanvasRight"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#createLeaveTypeModal"
                                      className="btn btnclr"
                                    >
                                      Performa
                                    </button>
                                  )}

                                  <button
                                    onClick={(e) =>
                                      handelStatus(e, ess.id, "accepted")
                                    }
                                    className="btn btnclr"
                                  >
                                    Accept
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      handelStatus(e, ess.id, "rejected")
                                    }
                                    className="btn btnclr"
                                  >
                                    Reject
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      handelStatus(e, ess.id, "talentpool")
                                    }
                                    className="btn btnclr"
                                  >
                                    Talent Pool
                                  </button>
                                  {/* Create modal content */}
                                  <div
                                    id="createLeaveTypeModal"
                                    className="modal fade"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-labelledby="myModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="myModalLabel"
                                          >
                                            Performa
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRightCreate"
                                            aria-controls="offcanvasRight"
                                          />
                                        </div>
                                        <form onSubmit={(e) => handelSubmit(e)}>
                                          <div className="modal-body">
                                            <div className="row">
                                              <div className="col-12 mb-3 d-flex">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    paddingRight: "20px",
                                                  }}
                                                  htmlFor="Name"
                                                >
                                                  Behavoiur
                                                </label>
                                                <input
                                                  type="text"
                                                  value={behavoiur}
                                                  onChange={(e) =>
                                                    setBehavoiur(e.target.value)
                                                  }
                                                  className="form-control"
                                                  id="Name"
                                                  placeholder="Title"
                                                  required
                                                />
                                              </div>
                                              <div className="col-12 mb-3 d-flex">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    paddingRight: "20px",
                                                  }}
                                                  htmlFor="Name"
                                                >
                                                  Domain Knowledge
                                                </label>
                                                <input
                                                  type="text"
                                                  value={domainKnowlege}
                                                  onChange={(e) =>
                                                    setDomainKnowlege(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                  id="Name"
                                                  placeholder="Title"
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-light waves-effect"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="submit"
                                              onClick={(e) => handelSubmit(e)}
                                              className="btn btn-primary waves-effect waves-light"
                                            >
                                              Save changes
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                      {/* /.modal-content modal-dialog-centere */}
                                    </div>
                                    {/* /.modal-dialog */}
                                  </div>
                                  {/* /.modal */}
                                </td>
                              </tr>
                            ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interviewed;

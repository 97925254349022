import React from "react";

const ExpRepeater = ({ ExpInputFields, setExpInputFields }) => {
  const handleFormChange = (index, event) => {
    let data = [...ExpInputFields];
    data[index][event.target.name] = event.target.value;
    setExpInputFields(data);
  };

  const removeFields = (index) => {
    let data = [...ExpInputFields];
    data.splice(index, 1);
    setExpInputFields(data);
  };

  return (
    <div className="row">
      {ExpInputFields.map((input, index) => {
        return (
          <>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="controls">
                <input
                  type="text"
                  className="form-control inputset"
                  id="email-addr"
                  name="organization_title"
                  placeholder="Organizations Title*"
                  required
                  value={input.organization_title}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="controls">
                <input
                  type="text"
                  className="form-control inputset"
                  id="email-addr"
                  placeholder="Designation*"
                  name="designation"
                  required
                  value={input.designation}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="date-picker">
                <input
                  type="date"
                  id="pass"
                  className="pickadate form-control inputset"
                  placeholder="Start Date*"
                  name="start_date"
                  required
                  value={input.start_date}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="date-picker">
                <input
                  type="date"
                  id="pass"
                  className="pickadate form-control inputset"
                  placeholder="End Date*"
                  name="end_date"
                  required
                  value={input.end_date}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
            </div>
            <div className="form-group col-sm-12 d-flex col-md-4 mb-3">
              <input
                type="text"
                className="form-control inputset"
                id="email-addr"
                name="last_salary"
                placeholder="Last drawn salary*"
                required
                value={input.last_salary}
                onChange={(event) => handleFormChange(index, event)}
              />
              {ExpInputFields.length === 1 ? null : (
                <button
                  type="button"
                  className=" d-flex justify-content-center align-items-center ml-1 btn"
                  onClick={() => {
                    removeFields();
                  }}
                >
                  <i className="uil-trash-alt" />
                </button>
              )}
            </div>
            <div className="form-group col-sm-12 d-flex justify-content-flex-end col-md-4" />
          </>
        );
      })}
    </div>
  );
};

export default ExpRepeater;

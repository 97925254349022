import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import LeavetypeService from "../../../Services/LeavetypeService";
import LeaveSessionService from "../../../Services/LeaveSessionService";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import moment from "moment";

const LeaveEntitlementHR = () => {
  const SelectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#00b5b8" : null,
        color: isFocused ? "#fff" : "#333333",

        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#00b5b8"
              : "#00b5b8"
            : undefined,
        },
      };
    },
  };
  const animatedComponents = makeAnimated();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leavePeriod, setLeavePeriod] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [LeaveEntitlementList, setLeaveEntitlementList] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [leavePeriodList, setLeavePeriodList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [entitlementVal, setEntitlementVal] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [selectedLeavePeriod, setSelectedLeavePeriod] = useState("");
  const compid = DataParser.CompanyId();

  useEffect(() => {
    getLeaveEntitlements();
    getCompanyUsers();
    getAllLeaveType();
    getLeaveSession();
  }, []);

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(compid).then((res) => {
      const Data = res.data.user;
      let newData = [];

      for (let i = 0; i < Data.length; i++) {
        let obj = { value: Data[i].id, label: Data[i].first_name };
        newData.push(obj);
      }
      setUsersList(newData);
    });
  };

  const getAllLeaveType = () => {
    LeavetypeService.getLeaveType(compid).then((res) => {
      const Data = res.data.leavetypes;
      const activeTypes = Data.filter((s) => s.status === 1);
      let newData = [];

      for (let i = 0; i < activeTypes.length; i++) {
        let obj = { value: activeTypes[i].id, label: activeTypes[i].name };
        newData.push(obj);
      }
      setLeaveTypeList(newData);
    });
  };

  const getLeaveSession = () => {
    LeaveSessionService.getLeaveSeassions(compid).then((res) => {
      const Data = res.data.Leave_sessions;
      let newData = [];

      for (let i = 0; i < Data.length; i++) {
        const title = `${moment(Data[i].start_date).format(
          "DD/MM/YYYY"
        )} - ${moment(Data[i].end_date).format("DD/MM/YYYY")}`;

        let obj = { value: Data[i].id, label: title };
        newData.push(obj);
      }
      setLeavePeriodList(newData);
    });
  };

  const getLeaveEntitlements = () => {
    setLoading(true);
    LeaveEntitlementService.getEntitlemente(compid).then((res) => {
      const Data = res.data.leave_entitlements;
      setLeaveEntitlementList(Data);
      setLoading(false);
    });
  };

  const filterSearchForAdmin = (DataA) => {
    if (employeeName === "") {
      return DataA;
    }
    return DataA.filter((el) =>
      el.Employee.toLowerCase().includes(employeeName.toLowerCase())
    );
  };
  const filterbyType = (DataA) => {
    if (leaveType === "") {
      return DataA;
    }
    return DataA.filter((el) => el.LeaveType === leaveType);
  };
  const filterbyPeriod = (DataA) => {
    if (leavePeriod === "") {
      return DataA;
    }
    return DataA.filter((el) => el.LeavePeriod === leavePeriod);
  };
  const allFilter = (DataForAdmin) => {
    return filterbyPeriod(filterbyType(filterSearchForAdmin(DataForAdmin)));
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    let users = [];
    for (let i = 0; i < selectedUser.length; i++) {
      users.push(selectedUser[i].value);
    }

    const Body = {
      company_id: compid,
      entitlement: entitlementVal,
      empid: users,
      leavetype: selectedLeaveType,
      leavesession: selectedLeavePeriod,
    };
    const link = document.getElementById("EntitlementCreateClose");
    LeaveEntitlementService.createEntitlement(Body).then((res) => {
      link.click();
      setEntitlementVal("");
      getLeaveEntitlements();
    });
  };

  const handelViewUser = (e, item) => {
    e.preventDefault();
    navigate(
      `/${DataParser.CompanyIdentifierRole()}/leave_entitlement/${item}`
    );
  };

  return (
    <>
      <Helmet>
        <title>Leaves Entitlement - Planit</title>
      </Helmet>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves Entitlement</h4>
                  <div className="page-title-right">
                    <>
                      <ol
                        className="breadcrumb"
                        style={{ marginBottom: "10px !important" }}
                      >
                        <li className="breadcrumb-item">
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/home`}
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {" "}
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/hr_settings`}
                          >
                            Settings
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Leaves Entitlement
                        </li>
                      </ol>
                      <button
                        style={{ float: "right" }}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createLeaveEntitlementModal"
                      >
                        Create Leave Entitlement
                      </button>
                      {/* Create modal content */}
                      <div
                        id="createLeaveEntitlementModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Leave Entitlement
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                id="EntitlementCreateClose"
                                aria-label="Close"
                              />
                            </div>
                            <form onSubmit={(e) => handelSubmit(e)}>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Employees
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <Select
                                        options={usersList}
                                        isMulti
                                        components={animatedComponents}
                                        styles={SelectStyle}
                                        onChange={(e) => setSelectedUser(e)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Leave Type
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <Select
                                        options={leaveTypeList}
                                        styles={SelectStyle}
                                        onChange={(e) =>
                                          setSelectedLeaveType(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Leave Period
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <Select
                                        options={leavePeriodList}
                                        styles={SelectStyle}
                                        onChange={(e) =>
                                          setSelectedLeavePeriod(e.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Entitlement
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={entitlementVal}
                                        id="example-number-input"
                                        onChange={(e) =>
                                          setEntitlementVal(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  onClick={(e) => handelSubmit(e)}
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </>
                  </div>
                </div>
              </div>
              {/* <div className="row d-flex mb-5">
                <div className="col-md-2">
                  <label htmlFor="employee_name">Employee Name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Employee Name"
                    id="employee_name"
                    value={employeeName}
                    onChange={(e) => setEmployeeName(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="leave_type">Leave Type</label>
                  <select
                    id="leave_type"
                    className="form-select"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Leave Type">Leave Type</option>
                    <option>Large select</option>
                    <option>Large select</option>
                    <option>Small select</option>
                  </select>
                </div>
                 <div className="col-md-2">
                   <label htmlFor="leave_status">Leave Period</label>
                   <select
                     id="leave_status"
                     className="form-select"
                     value={leavePeriod}
                     onChange={(e) => setLeavePeriod(e.target.value)}
                   >
                     <option value="">Select</option>
                     <option value="Status">Status</option>
                     <option>Large select</option>
                     <option>Small select</option>
                   </select>
                 </div>
               }
              </div> */}
              <div className="card">
                <div className="card-body">
                  <table
                    id="dataEable"
                    className="table mb-0 table-striped"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Leave Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {LeaveEntitlementList &&
                            LeaveEntitlementList.map((el, index) => (
                              <tr
                                key={el.id}
                                role="row"
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td className="tableset">{el.typ}</td>
                                <td className="tableset">
                                  {moment(el.start_date).format("YYYY-MM-DD")}
                                </td>
                                <td className="tableset">
                                  {moment(el.end_date).format("YYYY-MM-DD")}
                                </td>

                                <td className="tableset">
                                  <Link
                                    to="#"
                                    onClick={(e) => handelViewUser(e, el.id)}
                                  >
                                    <i className="uil-eye iconscolor" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveEntitlementHR;

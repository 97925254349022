import requests from "./httpService";

const LeaveEntitlementService = {
  getEntitlemente(id, body) {
    return requests.get(`/leaveentitlement/all?company_id=${id}`, body);
  },
  getEntitlementeEmployee(uid, id, body) {
    return requests.get(`/leaveentitlement/userleavesEntitlement/${uid}?company_id=${id}`, body);
  },
  createEntitlement(body) {
    return requests.post(`/leaveentitlement/create`, body);
  },
  viewEntitlemtUsers(id, compid, body) {
    return requests.get(
      `/leaveentitlement/employees/${id}?company_id=${compid}`,
      body
    );
  },
  updateUserEntitlement(id, body) {
    return requests.post(`/leaveentitlement/update/${id}`, body);
  },
  userLeaveEntitlement(id, body) {
    return requests.post(`/leaveentitlement/userleavesEntitlement/${id}`, body);
  },
};

export default LeaveEntitlementService;

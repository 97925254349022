import React, { useEffect, useState } from "react";
import DataParser from "../../../utlits/Dataparser";
import CompanyService from "../../../Services/CompanyService";
import Sidebar from "../../Layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CreateRole = () => {
  const companyId = DataParser.CompanyId();

  const [readPermssion, setReadPermissions] = useState([]);
  const [writePermissions, setWritePermissions] = useState([]);
  const [editPermissions, setEditPermissions] = useState([]);
  const [deletePermissions, setDeletePermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [name, setName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const companyId = DataParser.CompanyId();
    setIsLoading(true);
    CompanyService.getPermissions(companyId).then((res) => {
      const data = res.data;
      setReadPermissions(data.Read);
      setWritePermissions(data.Write);
      setEditPermissions(data.Edit);
      setDeletePermissions(data.Delete);
      setIsLoading(false);
    });
  }, []);

  const onPermissionsSelect = (id) => {
    //e.preventDefault();
    const alreadyExists = permissions.includes(id);
    if (alreadyExists) {
      const newData = permissions.filter((el) => el !== id);
      setPermissions(newData);
    } else {
      setPermissions([...permissions, id]);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const AddFomrData = {
        name: name,
        permission: permissions,
        company_id: companyId,
      };
      CompanyService.createCompanyRole(AddFomrData).then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
          setIsSending(false);
        }
        if (res.Good === true) {
          navigate(`/${DataParser.CompanyIdentifierRole()}/roles`);
          setIsSending(false);
        }
      });
    } catch (err) {}
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#navtabs-home"
                          role="tab"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            <i className="uil-user" />
                            &nbsp;General
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="navtabs-home"
                        role="tabpanel"
                      >
                        <form onSubmit={(e) => onSubmitHandler(e)}>
                          <div className="mb-3">
                            <div className="col-6">
                              <label className="form-label" htmlFor="role">
                                Role
                              </label>
                              <input
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                className="form-control"
                                id="role"
                                placeholder="Role"
                                required
                              />
                            </div>
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table mb-0 table-striped">
                                  <thead>
                                    <tr className="tableHeaderbg">
                                      <th>Read</th>
                                      <th>Write</th>
                                      <th>Edit</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <>
                                        <tr>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                          <td className="tableset">
                                            <Skeleton
                                              style={{ width: "40%" }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <tr>
                                        <td className="tableset">
                                          {readPermssion.map((read) => (
                                            <div
                                              className="form-check mb-3"
                                              key={read.id}
                                              onClick={(e) =>
                                                onPermissionsSelect(read.id)
                                              }
                                            >
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={read.id}
                                                name={read.name}
                                                value={read.id}
                                                checked={
                                                  permissions.includes(read.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={read.name}
                                                value={read.id}
                                              >
                                                {read.name}
                                              </label>
                                            </div>
                                          ))}
                                        </td>
                                        <td className="tableset">
                                          {writePermissions.map((write) => (
                                            <div
                                              className="form-check mb-3"
                                              key={write.id}
                                              onClick={(e) =>
                                                onPermissionsSelect(write.id)
                                              }
                                            >
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={write.id}
                                                name={write.name}
                                                value={write.id}
                                                checked={
                                                  permissions.includes(write.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={write.name}
                                                value={write.id}
                                              >
                                                {write.name}
                                              </label>
                                            </div>
                                          ))}
                                        </td>
                                        <td className="tableset">
                                          {editPermissions.map((edit) => (
                                            <div
                                              className="form-check mb-3"
                                              key={edit.id}
                                              onClick={(e) =>
                                                onPermissionsSelect(edit.id)
                                              }
                                            >
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={edit.id}
                                                name={edit.name}
                                                value={edit.id}
                                                checked={
                                                  permissions.includes(edit.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={edit.name}
                                                value={edit.id}
                                              >
                                                {edit.name}
                                              </label>
                                            </div>
                                          ))}
                                        </td>
                                        <td className="tableset">
                                          {deletePermissions.map((del) => (
                                            <div
                                              className="form-check mb-3"
                                              key={del.id}
                                              onClick={(e) =>
                                                onPermissionsSelect(del.id)
                                              }
                                            >
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={del.id}
                                                name={del.name}
                                                value={del.id}
                                                checked={
                                                  permissions.includes(del.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={del.name}
                                                value={del.id}
                                              >
                                                {del.name}
                                              </label>
                                            </div>
                                          ))}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                              {isSending || isLoading ? (
                                <>
                                  <button
                                    disabled
                                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                  >
                                    Save changes
                                  </button>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                  >
                                    Save changes
                                  </button>
                                </>
                              )}

                              <button
                                type="reset"
                                onClick={() => navigate(-1)}
                                className="btn btn-light"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRole;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import VacancyService from "../../../Services/VacancyService";
import DataParser from "../../../utlits/Dataparser";
import CreateCareer from "./CreateCareer";

const Career = () => {
  const comid = DataParser.CompanyId();
  const [VacanciesList, setVacanciesList] = useState([]);
  const [questionModified, setQuestionModified] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [displayItem, setDisplayItem] = useState(false);
  const getVacancyList = () => {
    VacancyService.getAllVacancies(comid).then((res) => {
      const data = res.data.hirings;
      setVacanciesList(data);
    });
  };

  useEffect(() => {
    getVacancyList();
  }, []);

  const onClickItem = (e, item) => {
    e.preventDefault();
    setDisplayItem(false);
    console.log("item", item.id);
    const data = VacanciesList.find((ess) => ess.id === item.id);
    console.log("Data", data);
    setSelectedItem(data);

    const mdata = data.questionanir;
    let arr = [];
    let newfields = {
      question: "",
      questionType: "",
      fields: [],
      selectedValue:""
    };
    for(let i = 0; i< mdata.length; i++){
        arr.push({
          question: mdata[i].question,
          questionType: mdata[i].questionType,
          fields: mdata[i].fields,
          selectedValue:""
        })
    }
    setQuestionModified(arr)
    setDisplayItem(true);
  };

  return (
    <>
      <CreateCareer
        offcanvasid={"offcanvasRightCreate"}
        labelledby={"offcanvasRightLabel"}
        selectedItem={selectedItem}
        questionModified={questionModified}
      />
      <div className="d-flex">
        <div
          className="col-lg-2 col-md-3 col-5 justify-content-start"
          style={{ height: 85 }}
        >
          <div className="row p-5">
            <nav className="navbar">
              <div className="container">
                <Link className="img-fluid org_logo_div" to="#">
                  <img
                    alt=""
                    className="img-fluid"
                    src="/assets/images/logo-dark.png"
                    id="logo"
                  />
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row p-5">
              <div className="col-lg-10 col-md-10 col-12" id="vacancy-wrapper">
                <b>
                  Available Vacancies at
                  <span className="org_name_div"> Planit</span>
                </b>
                <div id="vacancy-container">
                  {VacanciesList.map((es) => (
                    <div
                      className="card mb-3 mt-3 gap-3 vacancy_list show_vacancy_details"
                      data-id={0}
                    >
                      <div
                        className="card-body d-flex d-inline place-item-center"
                        style={{ alignItems: "center" }}
                      >
                        <i className="fas fa-briefcase" aria-hidden="true" />
                        <span
                          onClick={(e) => onClickItem(e, es)}
                          className="px-3"
                        >
                          {es?.title}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div
              className={
                displayItem
                  ? "specific_vacancy_detail ps-3"
                  : "specific_vacancy_detail ps-3 d-none"
              }
              id="specific_vacancy_detail"
            >
              <div className="row col mb-3 mt-5">
                <div className="col-8">
                  <p
                    className="fw-bold"
                    id="vacancy-title"
                    style={{ fontSize: 18 }}
                  >
                    {selectedItem && selectedItem?.title}
                  </p>
                </div>
                <div
                  className="col-4 d-flex justify-content-end"
                  id="apply_vacancy_div"
                >
                  <Link
                    to="#"
                    className="btn btn-primary font_12  fw-bold apply_for_vacancy_btn"
                    type="submit"
                    id="apply_for_vacancy"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightCreate"
                    aria-controls="offcanvasRight"
                  >
                    Apply
                  </Link>
                </div>
              </div>
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-venus-mars" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Gender</div>
                    <div className="font_14 " id="gender">
                      {selectedItem && selectedItem?.gender === "1"
                        ? "Male"
                        : null}
                      {selectedItem && selectedItem?.gender === "2"
                        ? "Female"
                        : null}
                      {selectedItem && selectedItem?.gender === "3"
                        ? "Both"
                        : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 11px" }}
                  >
                    <i className="fas fa-users" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Age Limit</div>
                    <div className="font_14" id="age-limit">
                      {selectedItem && selectedItem?.lower_age_limt} -{" "}
                      {selectedItem && selectedItem?.upper_age_limt}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-user-tie" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Job Type</div>
                    <div className="font_14" id="job-type">
                      {selectedItem && selectedItem?.job_type === 1
                        ? "Remote job"
                        : "In Office job"}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 15px" }}
                  >
                    <i className="fas fa-chair" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Total Seats</div>
                    <div className="font_14" id="total-seats">
                      {selectedItem && selectedItem?.avalaible_seats}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-calendar" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Deadline Date</div>
                    <div className="font_14" id="deadline-date">
                      {selectedItem && selectedItem?.expiry_date === ""
                        ? "Undisclosed"
                        : moment(selectedItem?.expiry_date).format(
                            "DD/MM/YYYY"
                          )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-flag" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Experience</div>
                    <div className="font_14" id="experience">
                      {selectedItem && selectedItem?.experience_required}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 15px" }}
                  >
                    <i className="fas fa-map-marker-alt" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Location</div>
                    <div className="font_14" id="location">
                      {selectedItem && selectedItem?.location}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-graduation-cap" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">
                      Required Education
                    </div>
                    <div className="text-wrap font_14" id="req-education">
                      {selectedItem && selectedItem?.required_education}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-12 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-file-alt" aria-hidden="true" />
                  </div>
                  <div className="px-2 pb-5">
                    <div className="fw-bold my_blue font_12">Description</div>
                    <div className="font_14" id="description">
                      {selectedItem && selectedItem?.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;

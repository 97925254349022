import requests from "./httpService";

const DepartmentServices = {
  getCompanyDepartment(id, body) {
    return requests.get(`/departments/all?company_id=${id}`, body);
  },
  createDepartment(body) {
    return requests.post(`/departments/create`, body);
  },
  deleteDepartment(id, body) {
    return requests.delete(`/departments/${id}`, body);
  },
  editDepartment(id, body) {
    return requests.post(`/departments/update/${id}`, body);
  },
  updateDepartment(id) {
    return requests.post(`/departments/statusupdate/${id}`);
  },
};

export default DepartmentServices;

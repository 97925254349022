import requests from "./httpService";

const ModuleService = {
  getModules(body) {
    return requests.get(`/modules/all`, body);
  },
  updateStatus(id, body) {
    return requests.post(`/modules/statusupdate/${id}`, body);
  },
  createModule(body) {
    return requests.post(`/modules/create`, body);
  },
  updateModules(id, body) {
    return requests.post(`/modules/update/${id}`, body);
  },
  moduleDelete(id, body) {
    return requests.delete(`/modules/delete/${id}`, body);
  },
};

export default ModuleService;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import VacancyService from "../../../Services/VacancyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import Performa from "./ApplicantsListing/Performa";

const Hire = () => {
  const comid = DataParser.CompanyId();
  const depuid = DataParser.departmentId();
  const roletitle = DataParser.userRoleTitle();
  const navigate = useNavigate();
  const [VacanciesList, setVacanciesList] = useState([]);
  const [TalentPoolList, setTalentPoolList] = useState([]);
  const [editId, setEditId] = useState("");
  const [vanclist, setVanciesList] = useState([]);

  const getVacancyList = (cid, dip) => {
    VacancyService.getAllVacancies(cid, dip).then((res) => {
      const data = res.data.hirings;
      setVacanciesList(data);
    });
  };

  const getTalentPoolVacancyList = () => {
    VacancyService.talentPoolAllVacancy().then((res) => {
      console.log("res:", res);
      const data = res.data.applicants;
      setTalentPoolList(data);
    });
  };

  const getVacncuiesAll = (cid) => {
    VacancyService.vacancyApplicants(cid).then((res) => {
      const data = res.data.applicants;
      console.log("dadadadad", data);
      setVanciesList(data);
    });
  };

  useEffect(() => {
    if (roletitle === "HR" || roletitle === "Company Admin") {
      getVacancyList(comid, "");
      getVacncuiesAll(comid);
    } else {
      getVacancyList(comid, depuid);
    }

    getTalentPoolVacancyList();
  }, []);

  const handelEdit = (e, item) => {
    e.preventDefault();
    setEditId(item.id);
    navigate(`/${DataParser.CompanyIdentifierRole()}/hire/${item.id}`);
  };

  const gotoLink = (e, item) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/hire/applicaton`, {
      state: {
        jobid: item.id,
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>Hiring - Planit</title>
      </Helmet>
      {/* <Sidebar /> */}
      <Performa
        offcanvasid={"offcanvasRightCreate"}
        labelledby={"offcanvasRightLabel"}
      />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Hiring</h4>
                  <div className="page-title-right">
                    {/* <button
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRightCreate"
                      aria-controls="offcanvasRight"
                      // data-bs-toggle="modal"
                      // data-bs-target="#createLeaveTypeModal"
                      className="btn btnclr"
                    >
                      Performa
                    </button> */}
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/hire/create`}
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Create vacancy
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 icon_div">
                <div className="row">
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#7571F9" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "applied"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#" className="app_btn open_pending_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/applicants.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span id="appli" className="font_12 font-weight-bold">
                      Applicants
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#F8C038" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "shortlisted"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#" className="app_btn open_shortlisted_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/shortlisted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Shortlisted
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#2ABFCC" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "interviewed"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#" className="app_btn open_interviewed_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/interviewed.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Interviewed
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#0ACF97" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "accepted"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#" className="app_btn open_accepted_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/accepted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Accepted</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#FF5E5E" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "rejected"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#" className="app_btn open_rejected_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/rejected.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Rejected</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#97CEF8" }}
                      >
                        {vanclist
                          .map(
                            (els) =>
                              els.applicants.filter(
                                (el) => el.status === "talentpool"
                              ).length
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)}
                      </legend>
                      <Link to="#">
                        <img
                          className="recruit_img"
                          src="/assets/images/talent-pool.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Talent Pool
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                      style={{ width: "70vh" }}
                    >
                      <li className="nav-item custome text-left">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#brief"
                          role="tab"
                          aria-selected="true"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            Vacancies List
                          </span>
                        </a>
                      </li>
                      <li className="nav-item text-left">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#leavepolicy"
                          role="tab"
                          aria-selected="false"
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user" />
                          </span>
                          <span className="d-none d-sm-block">Talent Pool</span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content pt-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="brief"
                        role="tabpanel"
                      >
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="card-body p-0">
                              <table className="table mb-0 table-striped">
                                <thead>
                                  <tr className="tableHeaderbg">
                                    <th>Title</th>
                                    <th>Age Limit</th>
                                    <th>Total Applicant</th>
                                    <th>Expiry</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {VacanciesList.map((es) => (
                                    <tr>
                                      <th
                                        onClick={(e) => gotoLink(e, es)}
                                        scope="row"
                                      >
                                        {es.title}
                                      </th>
                                      <td className="tableset">
                                        {es.lower_age_limt} -{" "}
                                        {es.upper_age_limt}
                                      </td>
                                      <td className="tableset">
                                        {es.applicantCount}
                                      </td>
                                      <td className="tableset">
                                        {moment(es.expiry_date).format(
                                          "YYY-MM-DD"
                                        )}
                                      </td>
                                      {es.status === 1 ? (
                                        <td className="tableset">
                                          <span className="btn btn-success waves-effect waves-light btn-sm">
                                            Yes
                                          </span>
                                        </td>
                                      ) : (
                                        <td className="tableset">
                                          <span className="btn btn-danger waves-effect waves-light btn-sm">
                                            No
                                          </span>
                                        </td>
                                      )}
                                      <td className="tableset">
                                        <Link
                                          to="#"
                                          onClick={(e) => handelEdit(e, es)}
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="leavepolicy"
                        role="tabpanel"
                      >
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="card-body p-0">
                              <table className="table mb-0 table-striped">
                                <thead>
                                  <tr className="tableHeaderbg">
                                    <th>Candidate</th>
                                    <th>Location</th>
                                    <th>Email</th>
                                    <th>Cv</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {TalentPoolList &&
                                    TalentPoolList.map((es) => (
                                      <tr key={es._id}>
                                        <th scope="row">
                                          {es.first_name} {es.last_name}
                                        </th>
                                        <td className="tableset">
                                          {es.location}
                                        </td>
                                        <td className="tableset">{es.email}</td>
                                        <td className="tableset">
                                          <a
                                            target="_blank"
                                            href={`${process.env.REACT_APP_CV_PATH}/${es.cv}`}
                                          >
                                            <i className="uil-eye iconscolor" />
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hire;

import secureLocalStorage from "react-secure-storage";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import LoginService from "../../../Services/LoginService";
import DataParser from "../../../utlits/Dataparser";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const loginData = {
      email: email,
      password: password,
    };
    LoginService.loginAdmin(loginData)
      .then((res) => {
        if (!res.Good) {
          setMessage(res.data);
          setLoading(false);
        } else {
          const cc = JSON.stringify(res);

          secureLocalStorage.setItem("adminInfo", JSON.stringify(res));
          secureLocalStorage.setItem("admins", res.authToken);
          const company = res.company_id;

          if (company === 0) {
            navigate(`/${DataParser.CompanyIdentifierRole()}/dashboard`);
            setLoading(false);
            window.location.reload(false);
          } else {
            navigate(`/${DataParser.CompanyIdentifierRole()}/home`);
            setLoading(false);
            window.location.reload(false);
          }
        }
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Login - Planit</title>
        <body className="authentication-bg" />
      </Helmet>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card  ">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <Link to="/" className="mb-3 d-block auth-logo">
                      <img
                        src="/assets/images/logo-dark.png"
                        alt="logo"
                        height={22}
                        className="logo"
                      />
                    </Link>

                    <p className="text-muted">Login with Planit</p>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      {message ? (
                        <div
                          className="alert alert-danger text-center mb-4"
                          role="alert"
                        >
                          {message}
                        </div>
                      ) : null}

                      <div className="mb-3">
                        <label className="form-label" htmlFor="useremail">
                          Email
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          id="useremail"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <label className="form-label" htmlFor="userpassword">
                          Password
                        </label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                          required
                        />
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="auth-remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 text-end">
                        {loading === false ? (
                          <button
                            className="btn w-sm waves-effect waves-light"
                            style={{
                              backgroundColor: "#00b5b8",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Log In
                          </button>
                        ) : (
                          <div
                            className="spinner-border m-1"
                            style={{ color: "#00b5b8" }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>
                  © Planit. Crafted with&nbsp;
                  <i className="mdi mdi-heart text-danger" /> by WebSouls
                </p>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
    </>
  );
};

export default Login;

import requests from "./httpService";

const WorkFlowsService = {
  getWorkflows(id, body) {
    return requests.get(`/get-workflows?company_id=${id}`, body);
  },
  createWorkflows(body) {
    return requests.post(`/create-workflow`, body);
  },
  deleteWorkflows(id, body) {
    return requests.get(`/del-workflow/${id}`, body);
  },
  updateWorkflows(id, body) {
    return requests.post(`/update-workflow/${id}`, body);
  },
  showWorkflows(id, body) {
    return requests.get(`/workflow-details/${id}`, body);
  },

  WorkflowTasks(id, body) {
    return requests.get(`/workflow-tasks/${id}`, body);
  },

  CreatWorkflowTasks(id, body) {
    return requests.post(`/create-workflow-task/${id}`, body);
  },
  showWorkflowtask(id, body) {
    return requests.get(`/workflow-task-details/${id}`, body);
  },
  updateWorkflowtask(id, body) {
    return requests.post(`/update-workflow-task/${id}`, body);
  },

  DeleteWorkflowTasks(id, body) {
    return requests.get(`/del-workflow-task/${id}`, body);
  },
};

export default WorkFlowsService;

import React, { useState, useEffect } from "react";
import CategoryService from "../../Services/CategoryService";
import CompanyService from "../../Services/CompanyService";
import DataParser from "../../utlits/Dataparser";

const CategoryOwnerField = (props) => {
  const { item } = props;
  const [departentList, setDeparmentList] = useState([]);
  const [selectedDepList, setSelectedDepList] = useState("");

  useEffect(() => {
    const CompanyId = DataParser.CompanyId();
    CompanyService.getCompanyDepartment(CompanyId).then((res) => {
      const data = res.data.departments;
      setDeparmentList(data);
    });
    setSelectedDepList(item.ownsership_dept);
  }, []);

  const handelDepChnage = (e, cat) => {
    e.preventDefault();
    setSelectedDepList(e.target.value);
    const CompanyId = DataParser.CompanyId();
    const deparment = e.target.value;
    const datasend = { company_id: CompanyId, dept: deparment };
    CategoryService.updateCategoryDep(cat.id, datasend);
  };

  return (
    <select
      className=" form-control sr"
      value={selectedDepList}
      onChange={(e) => handelDepChnage(e, item)}
    >
      <option value="">Please Select Department</option>
      {departentList &&
        departentList.map((dep) => (
          <option key={dep.id} value={dep.id}>
            {dep.name}
          </option>
        ))}
    </select>
  );
};

export default CategoryOwnerField;

import React from "react";
import shortid from "shortid";

const RepeaterForm = ({ inputFields, setInputFields, leaveTypeList }) => {
  const handleFormChange = (index, event) => {
    console.log("event: ", event.target.name);
    let data = [...inputFields];
    if (event.target.name === "leave_type_name") {
      const selectedText =
        event.target.options[event.target.selectedIndex].text;
      let value = event.target.value;
      data[index]["leave_type_name"] = selectedText;
      data[index]["leave_type_id"] = value;
      setInputFields(data);
    } else {
      data[index][event.target.name] = event.target.value;
      setInputFields(data);
    }
  };
  return (
    <>
      <div className="accordion" id="accordionExample">
        {inputFields.map((els, index) => (
          <React.Fragment key={shortid.generate()}>
            <div className="accordion-item mb-3">
              <h2
                className="accordion-header"
                id={`heading${els.leave_type_id}`}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${els.leave_type_id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${els.leave_type_id}`}
                >
                  {els.leave_type_name}
                </button>
              </h2>
              <div
            id={`collapse${els.leave_type_id}`}
            className={`accordion-collapse collapse ${
              index === inputFields.length - 1 ? "show" : ""
            }`}
            aria-labelledby={`heading${els.leave_type_id}`}
            data-bs-parent="#accordionExample"
          >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom03"
                        >
                          Leave Type{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <select
                          name="leave_type_name"
                          className="form-select"
                          onChange={(event) => handleFormChange(index, event)}
                          value={els.leave_type_id}
                        >
                          <option value="">Please Select</option>
                          {leaveTypeList.map((res) => (
                            <option key={res.id} value={res.id}>
                              {res.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom13"
                        >
                          Allowed Leaves{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom13"
                          placeholder="Allowed Leaves"
                          name="allowed_leaves"
                          required
                          value={els.allowed_leaves}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom04"
                        >
                          Unit{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <select
                          name="unit"
                          placeholder="Unit"
                          className="form-select"
                          onChange={(event) => handleFormChange(index, event)}
                          value={els.unit}
                        >
                          <option value="">Please Select</option>
                          <option value="days">Days</option>
                          <option value="hours">Hours</option>
                        </select>
                        {/* <input
                          type="number"
                          className="form-control"
                          id="validationCustom04"
                          name="unit"
                          placeholder="Unit"
                          required
                          value={els.unit}
                          onChange={(event) => handleFormChange(index, event)}
                        /> */}
                        <div className="invalid-feedback">
                          Please provide a valid unit.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom05"
                        >
                          Carry Forwad{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <input
                          type="number"
                          name="carry_forword"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="Carry Forwad"
                          required
                          value={els.carry_forword}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom05"
                        >
                          Prorated{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <select
                          name="prorated"
                          placeholder="Unit"
                          className="form-select"
                          onChange={(event) => handleFormChange(index, event)}
                          value={els.prorated}
                        >
                          <option value="">Please Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        {/* <input
                          type="number"
                          name="prorated"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="Carry Forwad"
                          required
                          value={els.prorated}
                          onChange={(event) => handleFormChange(index, event)}
                        /> */}
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom05"
                        >
                          Paid Leaves{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <input
                          type="number"
                          name="paid_leaves"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="Paid Leaves"
                          required
                          value={els.paid_leaves}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom05"
                        >
                          Encashable{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <input
                          type="number"
                          name="encashable"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="Encashable"
                          required
                          value={els.encashable}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom05"
                        >
                          Consecutive Allowed{" "}
                          <i
                            class="fa fa-info-circle"
                            data-toggle="tooltip"
                            title="first tooltip"
                          ></i>
                        </label>
                        <input
                          type="number"
                          name="consecutive_allowed"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="Consecutive Allowed"
                          required
                          value={els.consecutive_allowed}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid number.
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default RepeaterForm;

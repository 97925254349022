import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const AdminSettings = () => {
  const [userPermissions, setuserPermissions] = useState([]);

  useEffect(() => {
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h3 className="text mb-5">Settings</h3>
              <div className="page-title-right">
                <ol
                  className="breadcrumb"
                  style={{ marginBottom: "10px !important" }}
                >
                  <li className="breadcrumb-item">
                    <Link to={`/${DataParser.CompanyIdentifierRole()}/home`}>
                      Dashboard
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Settings</li>
                </ol>
              </div>
            </div>
         
          </div>
          <div className="row">
            <div className="col-md-6 col-xl-3">
              <Link to={`/${DataParser.CompanyIdentifierRole()}/lead_source`}>
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-presentation-lines-alt setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">Lead Source</h4>
                  </div>
                </div>
              </Link>
            </div>
            {userPermissions.indexOf("priority-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/priority`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-file-check setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">Piority</h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}
            {userPermissions.indexOf("communication-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link
                  to={`/${DataParser.CompanyIdentifierRole()}/communication`}
                >
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-user-arrows setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        Communication Source
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            {userPermissions.indexOf("categories-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/categories`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-transaction setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        Categories
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            {userPermissions.indexOf("role-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/roles`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-user-exclamation setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        Roles/Permssions
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            {userPermissions.indexOf("departments-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/departments`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-building setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        Departments
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            {userPermissions.indexOf("user-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/users`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-users-alt setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        {" "}
                        All Users
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            {userPermissions.indexOf("jobstatus-list") !== -1 ? (
              <div className="col-md-6 col-xl-3">
                <Link to={`/${DataParser.CompanyIdentifierRole()}/jobemplaoye`}>
                  <div className="card">
                    <div className="card-body setting_tabs">
                      <i
                        style={{ color: "#000" }}
                        className="uil-bag setting_tabs_icon"
                      />

                      <h4 className="mb-1 mt-1 setting_tabs_text">
                        {" "}
                        Employee job status
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null}

            <div className="col-md-6 col-xl-3">
              <Link
                to={`/${DataParser.CompanyIdentifierRole()}/companysettings`}
              >
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-bag setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">
                      Company Settings
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettings;

import requests from "./httpService";

const AttendanceService = {
  uploadAttendance(body) {
    return requests.post(`/attandence/create`, body);
  },
  attendancePunchout(body) {
    return requests.post(`/attandence/daypunchOut`, body);
  },
  attendancePunchoutNew(body) {
    return requests.post(`/attandence/daypunchOut`, body);
  },
  attendancePunchin(body) {
    return requests.post(`/attandence/daypunchIn`, body);
  },
  attendanceCheckPunchin(body) {
    return requests.post(`/attandence/checkpunchIn`, body);
  },
  attendanceCheckPunchinNew(body) {
    return requests.post(`/attandence/checkpunchIn`, body);
  },
  getAttendanceHr(compid, body) {
    return requests.get(`/attandence/all?company_id=${compid}`, body);
  },
  getAttendanceHrNew(compid, body) {
    return requests.get(`/attandence/aggreegate?company_id=${compid}`, body);
  },
  getAttendance(compid, usserId, body) {
    return requests.get(
      `/attandence/all?company_id=${compid}&user_id=${usserId}`,
      body
    );
  },
  getAttendanceNew(compid, usserId, body) {
    return requests.get(
      `/attandence/aggreegate?company_id=${compid}&user_id=${usserId}`,
      body
    );
  },
  updateAttendance(id, body) {
    return requests.post(`/attandence/update/${id}`, body);
  },
  getAttendancebySearch(compid, usserId, month, year, body) {
    return requests.get(
      `/attandence/all?company_id=${compid}&user_id=${usserId}&month=${month}&year=${year}`,
      body
    );
  },
  getAttendancebySearchNew(compid, usserId, month, year, body) {
    return requests.get(
      `/attandence/aggreegate?company_id=${compid}&user_id=${usserId}&month=${month}&year=${year}`,
      body
    );
  },
  getAttendancebySearchHrNew(compid, usserId,dept, month, year, body) {
    return requests.get(
      `/attandence/aggreegate?company_id=${compid}&user_id=${usserId}&deptId=${dept}&month=${month}&year=${year}`,
      body
    );
  },
};

export default AttendanceService;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import { Helmet } from "react-helmet";
import StatusBadge from "../../Reuseable/StatusBadge";
import secureLocalStorage from "react-secure-storage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Packages = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    CompanyService.getCompanyPackages().then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.packages;
      setPackages(data);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);
  const handelEditItem = (e, item) => {
    e.preventDefault();
    const id = item.id;

    navigate(`/${DataParser.CompanyIdentifierRole()}/packages/edit/${id}`, {
      state: {
        data: item,
      },
    });
  };
  const handelDeleteItem = (e, item) => {
    e.preventDefault();
    const id = item.id;
    const newData = packages.filter((el) => el.id !== id);
    setPackages(newData);
    CompanyService.deleteCompanyPackages(id).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Packages - Planit</title>
      </Helmet>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row mb-1">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Packages</h4>
                  <div className="page-title-right">
                    {userPermissions.indexOf("package-create") !== -1 ? (
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/packages/create`}
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Create Package
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Package</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Modules</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <th scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {packages &&
                                packages.map((item, index) => (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td className="tableset">{item.name}</td>
                                    <td className="tableset">{item.price}</td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "package-edit"
                                      ) !== -1 ? (
                                        <StatusBadge
                                          item={item}
                                          api={
                                            CompanyService.updatePackageStatus
                                          }
                                        />
                                      ) : (
                                        <>
                                          {item.status === 1 ? (
                                            <span className="badge badge-success">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="badge badge-danger">
                                              Deactive
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {item.packages_modules
                                        .filter((els) => els !== "")
                                        .map((el, index) => (
                                          <>
                                            <span
                                              className="btn btn-success waves-effect waves-light btn-sm text-capitalize"
                                              key={index}
                                            >
                                              {el}
                                            </span>
                                            &nbsp;
                                          </>
                                        ))}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "package-delete"
                                      ) !== -1 ? (
                                        <span
                                          className="cursor-pointer"
                                          onClick={(e) =>
                                            handelDeleteItem(e, item)
                                          }
                                        >
                                          <i className="uil-trash iconscolor" />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {userPermissions.indexOf(
                                        "package-edit"
                                      ) !== -1 ? (
                                        <span
                                          className="cursor-pointer"
                                          onClick={(e) =>
                                            handelEditItem(e, item)
                                          }
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import StatusBadge from "../../Reuseable/StatusBadge";
import DataParser from "../../../utlits/Dataparser";
import ModuleService from "../../../Services/ModuleService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Modules = () => {
  const [status, setStatus] = useState(true);
  const [name, setName] = useState("");
  const [editStatus, setEditStatus] = useState(null);
  const [editName, setEditName] = useState("");

  const [modulesList, setModulesList] = useState([]);
  const [editId, setEditId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onStatusChange = (e) => {
    setStatus(!status);
  };

  const onEditStatusChange = (e) => {
    setEditStatus(!editStatus);
  };

  const handelDelete = (e, item) => {
    e.preventDefault();
    ModuleService.moduleDelete(item.id).then((res) => {
      getAllModules();
    });
  };
  const handelEdit = (e, item) => {
    e.preventDefault();
    setEditStatus(item.status);
    setEditName(item.name);
    setEditId(item.id);
  };

  const getAllModules = () => {
    setIsLoading(true);
    ModuleService.getModules().then((res) => {
      console.log(res.data);
      setModulesList(res.data.modules);
      setIsLoading(false);
    });
  };

  const createModles = (e) => {
    const link = document.querySelector("#closeModuleCreate");
    e.preventDefault();
    let body = {
      name: name,
    };
    ModuleService.createModule(body).then((res) => {
      setName("");
      getAllModules();
      link.click();
    });
  };

  const editModules = (e) => {
    e.preventDefault();
    const link = document.querySelector("#editCloseModules");
    let body = {
      name: editName,
    };
    ModuleService.updateModules(editId, body).then((res) => {
      link.click();
      setEditId("");
      getAllModules();
    });
  };

  useEffect(() => {
    getAllModules();
  }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Modules</h4>
                  <div className="page-title-right">
                    <>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createMoulesModal"
                      >
                        Create Module
                      </button>
                      {/* Create modal content */}
                      <div
                        id="createMoulesModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Module
                              </h5>
                              <button
                                id="closeModuleCreate"
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12 mb-3 d-flex">
                                  <label
                                    className="form-label"
                                    style={{ paddingRight: "20px" }}
                                    htmlFor="Name"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Name"
                                    placeholder="Title"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                {/* <div className="col-12 mb-3 d-flex">
                                  <label
                                    className="form-label"
                                    style={{ paddingRight: "20px" }}
                                    htmlFor="Name"
                                  >
                                    Status
                                  </label>
                                  <div
                                    className="form-check form-switch form-switch-md mb-3"
                                    dir="ltr"
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd"
                                      onChange={(e) => onStatusChange(e)}
                                      defaultChecked={status}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                onClick={(e) => createModles(e)}
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <th className="tableset">
                                  <Skeleton />
                                </th>
                                <th className="tableset">
                                  <Skeleton />
                                </th>
                                <th className="tableset">
                                  <Skeleton />
                                </th>
                              </tr>
                            </>
                          ) : (
                            <>
                              {" "}
                              {modulesList &&
                                modulesList.map((item, index) => (
                                  <tr key={item.id}>
                                    <th className="tableset" scope="row">
                                      {index + 1}
                                    </th>
                                    <th className="tableset">{item.name}</th>
                                    <th className="tableset">
                                      <StatusBadge item={item} api={ModuleService.updateStatus} />
                                    </th>
                                    <th className="tableset">
                                      <Link
                                        to="#"
                                        className="cursor-pointer iconscolor"
                                        onClick={(e) => handelDelete(e, item)}
                                      >
                                        <i className="uil-trash iconscolor" />
                                      </Link>

                                      <Link
                                        to="#"
                                        className="cursor-pointer iconscolor"
                                        onClick={(e) => handelEdit(e, item)}
                                      >
                                        <i
                                          className="uil-pen iconscolor"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editMoulesModal"
                                        />
                                      </Link>
                                    </th>
                                  </tr>
                                ))}
                            </>
                          )}
                          {/* edit modal content */}
                          <div
                            id="editMoulesModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="myModalLabel">
                                    Edits Module
                                  </h5>
                                  <button
                                    id="editCloseModules"
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-12 mb-3 d-flex">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Title"
                                        required
                                        value={editName}
                                        onChange={(e) =>
                                          setEditName(e.target.value)
                                        }
                                      />
                                    </div>
                                    {/* <div className="col-12 mb-3 d-flex">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Status
                                      </label>
                                      <div
                                        className="form-check form-switch form-switch-md mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          onChange={(e) =>
                                            onEditStatusChange(e)
                                          }
                                          defaultChecked={editStatus}
                                        />
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light waves-effect"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    onClick={(e) => editModules(e)}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                              {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                          </div>
                          {/* /.modal */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modules;

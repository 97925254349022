import { DatePicker, Popconfirm } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import TaskServices from "../../../Services/TaskServices";
import DataParser from "../../../utlits/Dataparser";
import ImageUtlits from "../../../utlits/ImageUtlits";
import UserProfile from "../../Reuseable/UserProfile.";

const EditTask = (props) => {
  const {
    mid,
    EditId,
    labelledby,
    title,
    assignedTo,
    dueDate,
    periority,
    approval,
    status,
    description,
    setTitle,
    setAssignedTo,
    setDueDate,
    setPeriority,
    setApproval,
    setStatus,
    setDescription,
    userPermissions,
    tasksAssignetToME,
    setTaskAssignetToME,
    reFreshData,
    TaskData,
    priority,
    taskOwner,
    statuses,
    owner,
    TaskNotification,
    TaskNote,
    setUpdatingNotes,
    updatingNotes,
    handelDeleteNote,
    TaskNotes,
    handleSubmit,
    editLoading,
    setEditLoading,
    TaskNotesAttachmentImages,
    UpdateNotesAttac,
    isUploding,
    setIsUploding,
    TaskAttachmentImages,
    TaskViewAttachment,
    TaskNotifications,
  } = props;

  const [displaySubTask, setDisplaySubTask] = useState("none");
  //   SubTask State

  const [subTaskTitle, setSubTaskTitle] = useState("");
  const [subDueDate, setSubDueDate] = useState("");
  const [subAssignedTo, setSubAssignedTo] = useState("");
  const [UpdatedNote, setUpdatedNote] = useState("");
  const [noteEdit, setNoteEdit] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();

  const [noteAttachment, setNoteAttachment] = useState(null);
  const [commentForTaskNote, setCommentForTaskNote] = useState(null);

  const enUrl = process.env.REACT_APP_NOTES_PATH;

  useEffect(() => {
    const today = new Date();
    setSubDueDate(today);
    TaskNotesAttachmentImages(EditId);
    TaskAttachmentImages(EditId);
  }, []);

  setTimeout(() => {
    setEditLoading(false);
  }, 2000);
  const onDueDateChange = (date) => {
    setSubDueDate(date);
  };

  const noteUpdated = (e, id) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      notes: UpdatedNote,
    };
    TaskServices.noteUpdate(id, body).then((res) => {
      setUpdatingNotes(!updatingNotes);
      TaskNotes(EditId);
    });
    setEditNote(false);
  };

  const confirm = (e) => {
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };
    const newData = tasksAssignetToME.filter((el) => el.id !== EditId);
    setTaskAssignetToME(newData);
    TaskServices.delTask(EditId, body).then((res) => {
      reFreshData(e);
      var link = document.getElementById("CloseTaskEdit");
      link.click();
    });
  };

  const handleSubTaskSubmit = (e) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      title: subTaskTitle,
      assign_to: subAssignedTo,
      due_date: subDueDate,
      status: "pending",
    };
    TaskServices.createSubTask(EditId, body)
      .then((res) => {
        const datee = DataParser.getDateinFormat();
        setSubTaskTitle("");
        setSubAssignedTo("");
        setSubDueDate(datee);
        const link = document.getElementById("subtaskclose");
        link.click();
        reFreshData(e);
      })
      .catch((err) => console.log("Erroe"));
  };

  const makrascomplet = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();

    const dataLead = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      status: "complete",
    };
    TaskServices.updateTaskStatus(EditId, dataLead).then((res) => {
      var link = document.getElementById("CloseTaskEdit");
      reFreshData(e);
      link.click();
    });
  };

  const pinToTop = (e, id) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };
    TaskServices.pinToTop(id, body).then((res) => {
      setUpdatingNotes(!updatingNotes);
      TaskNotes(EditId);
    });
  };

  const editBlockId = (e, id) => {
    e.preventDefault();
    setEditNoteId(id);
    setEditNote(!editNote);
    TaskNotes(EditId);
  };
  const AddTaskNote = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", noteAttachment);
    formdata.append("notes", commentForTaskNote);
    formdata.append("company_id", companyId);
    formdata.append("company_identifier", companyIdentifier);

    const checkAuth =
      secureLocalStorage.getItem("adminInfo") === undefined
        ? ""
        : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}tasks/addnote/${EditId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authToken: `${checkAuth}`,
          },
        }
      )
      .then((res) => {
        setCommentForTaskNote("");
        setNoteAttachment(null);
        setUpdatingNotes(!updatingNotes);
        TaskNotes(EditId);
        TaskNotesAttachmentImages(EditId);
        TaskNotifications(EditId);
      })
      .catch((err) => console.log("Failed", err.message));
  };

  const addAttachment = (e) => {
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    formdata.append("company_id", companyId);
    formdata.append("company_identifier", companyIdentifier);
    // const id = 0;
    const checkAuth =
      secureLocalStorage.getItem("adminInfo") === undefined
        ? ""
        : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;

    if (e.target.files[0]) {
      setIsUploding(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}tasks/attachments/${EditId}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authToken: `${checkAuth}`,
            },
          }
        )
        .then((res) => {
          TaskAttachmentImages(EditId);
        });
    }
  };

  const onDateChange = (date) => {
    setDueDate(date);
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={mid}
        aria-labelledby={labelledby}
        style={{ width: "650px" }}
      >
        <div
          className="offcanvas-header border-bottom w-100"
          style={{ padding: "10px 10px 10px 10px" }}
        >
          <div className="col-md-9 d-flex">
            <h5 style={{ margin: "0px" }} id={labelledby}>
              Edit Tasks
            </h5>
          </div>
          <div className="col-md-3">
            <ul className="headerClose">
              <li style={{ paddingRight: "6px" }}>
                <div className="image-upload">
                  <label style={{ margin: "0px" }} for="file-input">
                    <i
                      style={{
                        color: "#6f7782",
                        cursor: "pointer",
                        fontSize: "19px",
                      }}
                      className="uil-paperclip"
                    />
                  </label>

                  <input id="file-input" onChange={addAttachment} type="file" />
                </div>
              </li>
              {userPermissions.indexOf("tasks-delete") !== -1 ? (
                <li style={{ paddingRight: "6px" }}>
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure to delete this Task?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                    style={{ "z-index": 9999 }}
                  >
                    <span type="button">
                      <i
                        style={{ color: "#6f7782", fontSize: "19px" }}
                        className="uil-trash"
                      />
                    </span>
                  </Popconfirm>
                </li>
              ) : null}
              {TaskData?.parent_id === 0 ? (
                <li style={{ paddingRight: "10px" }}>
                  <Link to="#" title="Add SubTask">
                    <i
                      style={{ color: "#6f7782", fontSize: "19px" }}
                      onClick={(e) => setDisplaySubTask("block")}
                      className=" uil-align-center-alt"
                      id="addsubtask"
                    />
                  </Link>
                </li>
              ) : null}

              {DataParser?.UserId() === TaskData?.created_by?.id &&
              TaskData.status === "pending-approval" ? (
                <>
                  <li style={{ paddingRight: "10px" }}>
                    <Link
                      onClick={(e) => makrascomplet(e)}
                      to="#"
                      style={{ color: "#6f7782", fontSize: "15px" }}
                      title="Mark as Complete"
                    >
                      <i className="fa fa-check" />
                    </Link>
                  </li>
                </>
              ) : null}

              <li>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  id="CloseTaskEdit"
                  aria-label="Close"
                />
              </li>
            </ul>
          </div>
        </div>
        {editLoading ? (
          <>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
              <div style={{ paddingRight: "2rem" }} className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <div className="controls row">
                      <div className="col-sm-12 title_font_size">
                        <Skeleton
                          style={{
                            width: "95%",
                            paddingTop: "10px",
                            marginLeft: "1rem",
                            paddingBottom: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="form-group  mt-2">
                    <div className="controls row">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="offcanvas-body" style={{ padding: "1rem 1rem" }}>
              <form>
                <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
                  <div style={{ paddingRight: "2rem" }} className="row">
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <div className="controls row">
                          <div className="col-sm-12 title_font_size">
                            <input
                              type="text"
                              id="titleid"
                              className="form-control w-100 inputset canvous-title-field"
                              placeholder="Title"
                              style={{ border: "0px" }}
                              name="title"
                              required
                              data-validation-required-message="This role field is required"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect"
                          >
                            Assigned to<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <select
                            className="form-control selectset custominput"
                            style={{ border: "0px" }}
                            name="lead_owner"
                            id="basicSelect"
                            required
                            value={assignedTo}
                            onChange={(e) => setAssignedTo(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {taskOwner &&
                              taskOwner.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.first_name} {el.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect1"
                          >
                            Due Date
                          </label>
                        </div>
                        <div className="col-md-9">
                          <div className="date-picker">
                            <DatePicker
                              disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              id="basicSelect1"
                              bordered={false}
                              allowClear={false}
                              value={moment(dueDate)}
                              onChange={onDateChange}
                              format={"DD-MM-YYYY"}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect2"
                          >
                            Periority
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <select
                            className="form-control selectset custominput"
                            style={{ border: "0px" }}
                            name="lead_owner"
                            id="basicSelect2"
                            required
                            value={periority}
                            onChange={(e) => setPeriority(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {priority &&
                              priority.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect3"
                          >
                            Approval Required
                            <span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <select
                            className="form-control selectset custominput"
                            style={{ border: "0px" }}
                            name="lead_owner"
                            id="basicSelect3"
                            required
                            value={approval}
                            onChange={(e) => setApproval(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect4"
                          >
                            Task Status<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <select
                            className="form-control selectset custominput"
                            style={{ border: "0px" }}
                            name="lead_owner"
                            id="basicSelect4"
                            required
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {statuses &&
                              statuses.map((el) => (
                                <option key={el} value={el}>
                                  {el}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-12 col-sm-12">
                      <fieldset
                        className="form-group row mt-2"
                        style={{ marginRight: "0px" }}
                      >
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect5"
                          >
                            Description
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <textarea
                            className="form-control w-100 autosize textset canvous-textarea-field-edit"
                            id="basicSelect5"
                            rows={3}
                            placeholder="Description"
                            name="description"
                            data-validation-required-message="This role field is required"
                            data-gramm="false"
                            wt-ignore-input="true"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </form>

              <div
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                className={
                  TaskData?.parent_id !== 0
                    ? "d-flex justify-content-end pl-1 mt-1 pb-1"
                    : "d-flex justify-content-between pl-1 mt-1 mb-4"
                }
              >
                {TaskData?.parent_id === 0 ? (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn add_new_task_btn glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                      id="addsubtask"
                      onClick={(e) => setDisplaySubTask("block")}
                    >
                      + Add Subtask
                    </button>
                  </>
                ) : null}
                <button
                  onClick={(e) => handleSubmit(e, EditId)}
                  style={{ marginRight: "10px" }}
                  className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                >
                  Submit
                </button>
              </div>
              <form
                id="compose-form"
                className="mt-1 tasks_custom_styling closing_class"
                onSubmit={(e) => handleSubTaskSubmit(e)}
              >
                <div
                  id="subtask"
                  className="tasks_custom_styling closing_class"
                  style={{ display: displaySubTask }}
                >
                  <div className="row position-relative px-3 pt-2">
                    <div
                      style={{
                        textAlign: "right",
                        cursor: "pointer",
                        paddingRight: "1rem",
                      }}
                      className="close_button"
                      id="subtaskclose"
                      onClick={(e) => setDisplaySubTask("none")}
                    >
                      X
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <div className="controls row">
                          <div className="col-sm-3 pl-2">
                            <label
                              style={{ paddingLeft: "1rem" }}
                              className="offcanvas_labels"
                            >
                              Title<span className="alert_star">*</span>
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control "
                              placeholder="Title"
                              name="title"
                              required
                              value={subTaskTitle}
                              onChange={(e) => setSubTaskTitle(e.target.value)}
                              data-validation-required-message="This role field is required"
                              style={{ border: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <div className="controls row">
                          <div className="col-sm-3 pl-2">
                            <label
                              style={{ paddingLeft: "1rem" }}
                              className="offcanvas_labels"
                            >
                              Due Date
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="date-picker">
                              <DatePicker
                                disabledDate={(current) => {
                                  let customDate =
                                    moment().format("YYYY-MM-DD");
                                  return (
                                    current &&
                                    current < moment(customDate, "YYYY-MM-DD")
                                  );
                                }}
                                bordered={false}
                                allowClear={false}
                                value={moment(subDueDate)}
                                onChange={onDueDateChange}
                                format={"DD-MM-YYYY"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-9"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row">
                        <div className="col-sm-3 pl-2">
                          <label
                            style={{ paddingLeft: "1rem" }}
                            className="offcanvas_labels"
                            htmlFor="basicSelect"
                          >
                            Assigned to<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <select
                            className="form-control"
                            name="assign_to"
                            id="basicSelect"
                            required
                            value={subAssignedTo}
                            onChange={(e) => setSubAssignedTo(e.target.value)}
                            style={{ border: "none" }}
                          >
                            <option value="">Select Option</option>
                            {taskOwner &&
                              taskOwner.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.first_name} {el.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1 mb-3">
                      {TaskData?.parent_id === 0 ? (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                          >
                            Add Subtask
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>

              {TaskViewAttachment && TaskViewAttachment.length === 0 ? null : (
                <div id="leadsattachments " style={{ padding: "0 20px" }}>
                  <h4>Task Attachments</h4>
                  {isUploding ? (
                    <>
                      <div className="uploaded_attachments">
                        <div className="attactment_img">
                          <Skeleton style={{ height: "100%" }} />
                        </div>
                      </div>
                      {TaskViewAttachment &&
                        TaskViewAttachment.map((item, index) => (
                          <Fade>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={enUrl + item.attachment}
                              download
                              key={index}
                              style={{ paddingRight: "5px" }}
                            >
                              <div className="uploaded_attachments">
                                <div
                                  className="attactment_img"
                                  style={{ marginRight: "5px" }}
                                >
                                  {ImageUtlits.leadImageSetting(
                                    item.attachment
                                  )}
                                </div>
                              </div>
                            </a>
                          </Fade>
                        ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <>
                        {TaskViewAttachment &&
                          TaskViewAttachment.map((item, index) => (
                            <Fade>
                              <a
                                target="_blank"
                                href={enUrl + item.attachment}
                                download
                                key={index}
                                style={{ paddingRight: "5px" }}
                              >
                                <div className="uploaded_attachments">
                                  <div
                                    className="attactment_img"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {ImageUtlits.leadImageSetting(
                                      item.attachment
                                    )}
                                  </div>
                                </div>
                              </a>
                            </Fade>
                          ))}
                      </>
                    </>
                  )}
                </div>
              )}
              {UpdateNotesAttac && UpdateNotesAttac.length === 0 ? null : (
                <span id="noteattachments">
                  <h4>Notes Attachments</h4>
                  {UpdateNotesAttac &&
                    UpdateNotesAttac.map((item, index) => (
                      <a
                        href={enUrl + item}
                        download
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="uploaded_attachments">
                          <div
                            className="attactment_img mr-1"
                            style={{ marginRight: "5px" }}
                          >
                            {ImageUtlits.leadImageSetting(item)}
                          </div>
                        </div>
                      </a>
                    ))}
                </span>
              )}

              <div className="task_story_feed">
                <div className="notes_detail subtask_outlook">
                  <div className="assignee_thumbnail">
                    <UserProfile
                      username={`${owner?.first_name} ${owner?.last_name}`}
                      userImage={owner?.image}
                    />
                  </div>
                  <div className="verticaly_centered_text">
                    <div className="assignee_title">
                      <p>
                        {owner?.first_name}&nbsp;{owner?.last_name} Created this
                        task&nbsp;
                        <span>
                          {moment(TaskData?.created_at).startOf().fromNow()}
                          {/* {DataParser.getNumberOfDays(
                              LeadViewData?.created_at,
                              currentDate
                            )}{" "}
                            days ago */}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {TaskNotification && TaskNotification.length === 0 ? null : (
                  <>
                    {TaskNotification &&
                      TaskNotification.map((item, index) => (
                        <div className="subtask_detail_manage" key={index}>
                          <div className="assignee_title d-flex">
                            <Link to="#">{item.created_by.first_name}</Link>
                            <p>
                              {item.title}{" "}
                              <span>
                                {" "}
                                {moment(item?.created_at)
                                  .startOf()
                                  .fromNow()}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
              <div id="notes">
                {TaskNote &&
                  TaskNote.map((el) => (
                    <div
                      className={
                        el.pin_top === 1
                          ? `notes_detail pintotpcolor`
                          : "notes_detail"
                      }
                      style={{ backgroundColor: "", paddingRight: "5px" }}
                      key={el.id}
                    >
                      <div className="assignee_thumbnail">
                        <UserProfile
                          username={el.user_name}
                          userImage={el?.user_image}
                        />
                      </div>
                      <div className="verticaly_centered_text">
                        <div className="assignee_title">
                          <p>
                            {el.user_name} &nbsp;
                            <span>
                              {moment(el?.created_at).startOf().fromNow()}
                              {/* {DataParser.getNumberOfDays(
                              el?.created_at,
                              currentDate
                            )}
                            &nbsp; days ago */}
                            </span>
                          </p>
                        </div>
                        <div className="assignee_description">
                          {!noteEdit ? (
                            <p
                              style={
                                editNote && el.id === editNoteId
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              id="p-34"
                            >
                              {el.notes}
                            </p>
                          ) : null}
                          {el.attachments && el.attachments.length > 0 ? (
                            <>
                              {el.attachments.map((ell) => (
                                <a href={enUrl + ell} download key={ell}>
                                  <div className="uploaded_attachments mb-0">
                                    <div className="uploaded_img attactment_img mr-1">
                                      {ImageUtlits.leadImageSetting(ell)}
                                    </div>
                                  </div>
                                </a>
                              ))}
                            </>
                          ) : null}
                          <textarea
                            style={
                              editNote && el.id === editNoteId
                                ? { display: "block" }
                                : { display: "none" }
                            }
                            className="noteedit w-100"
                            name="edit_notes"
                            id="txt-21"
                            data-note-id={21}
                            defaultValue={el.notes}
                            onMouseOut={(e) => noteUpdated(e, el.id)}
                            onChange={(e) => setUpdatedNote(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="like_icon dropdonw">
                          <span
                            className="dropdown "
                            style={{ paddingLeft: 5 }}
                          >
                            <div className="btn-group mt-2 me-1 dropstart">
                              <span
                                className="waves-effect waves-light dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="uil-ellipsis-v" />
                              </span>
                              <div
                                className="dropdown-menu transformsetsssss"
                                style={{}}
                              >
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={(e) => pinToTop(e, el.id)}
                                >
                                  {el.pin_top !== 1 ? "Pin to top" : "Unpin"}
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={(e) => editBlockId(e, el.id)}
                                >
                                  Edit Note
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={(e) => handelDeleteNote(e, el.id)}
                                >
                                  Delete Note
                                </Link>
                              </div>
                            </div>

                            <Link
                              className=""
                              id="todo-sidebar-dropdown"
                              // data-toggle="dropdown"
                              // aria-haspopup="true"
                              // aria-expanded="true"
                              to="#"
                            ></Link>
                          </span>
                        </div>

                        {/* <div className="like_icon dropdonw">
                          {el.pin_top === 1 ? (
                            <i className="fa fa-thumb-tack"></i>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-light waves-effect waves-light dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <i className="uil-ellipsis-v" />
                          </button>
                          <div className="dropdown-menu">
                            {el.pin_top !== 1 ? (
                              <Link
                                to="#"
                                className="dropdown-item pin"
                                onClick={(e) => pinToTop(e, el.id)}
                              >
                                Pin to top
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className="dropdown-item pin"
                                onClick={(e) => pinToTop(e, el.id)}
                              >
                                Unpin
                              </Link>
                            )}

                            <Link
                              to="#"
                              className="dropdown-item ed"
                              data-note-id={11}
                              onClick={(e) => editBlockId(e, el.id)}
                            >
                              Edit Note
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item del"
                              data-note-id={11}
                              onClick={(e) => handelDeleteNote(e, el.id)}
                            >
                              Delete Note
                            </Link>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="offcanvas-footer">
              <form onSubmit={(e) => AddTaskNote(e)}>
                <div className="add_noted_section">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <div className="controls add_notes">
                        <div className="d-flex">
                          <label className="float-left">
                            Add an attachment with notes
                          </label>

                          <div className="image-upload">
                            <label htmlFor="file-input-notes">
                              <i
                                style={{
                                  color: "#6f7782",
                                  cursor: "pointer",
                                  fontSize: "15px",
                                  paddingLeft: "3px",
                                }}
                                className="uil-paperclip"
                              />
                            </label>

                            <input
                              id="file-input-notes"
                              onChange={(e) =>
                                setNoteAttachment(e.target.files[0])
                              }
                              type="file"
                            />
                          </div>
                        </div>
                        <textarea
                          className="form-control notess"
                          placeholder="Notes"
                          name="notes"
                          required
                          data-validation-required-message="This role field is required"
                          style={{ borderColor: "#E4E7ED" }}
                          value={commentForTaskNote}
                          onChange={(e) =>
                            setCommentForTaskNote(e.target.value)
                          }
                        />
                        <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                          <button
                            type="submit"
                            className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                            data-lead={30}
                            id="notess"
                          >
                            Add Notes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditTask;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Roles = () => {
  const [rolesList, setRoleList] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getData = () => {
    setIsLoading(true);
    const companyId = DataParser.CompanyId();
    CompanyService.getCompanyRoles(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.roles;
      setRoleList(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handelDeleteItem = (e, id) => {
    e.preventDefault();
    const newData = rolesList.filter((el) => el.id !== id);
    setRoleList(newData);
    CompanyService.deleteCompanyRoles(id).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  const handelEditItem = (e, item) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/roles/edit`, {
      state: {
        data: item,
      },
    });
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content  Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Roles</h4>
                  <div className="page-title-right">
                    <ol
                      className="breadcrumb"
                      style={{ marginBottom: "10px !important" }}
                    >
                      <li className="breadcrumb-item">
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/home`}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/adminsettings`}
                        >
                          Settings
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Roles</li>
                    </ol>
                    <Link
                      style={{ float: "right" }}
                      to={`/${DataParser.CompanyIdentifierRole()}/roles/create`}
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Add
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0  table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Role</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {rolesList &&
                                rolesList.map((item, index) => (
                                  <tr key={item.id}>
                                    <th className="tableset" scope="row">
                                      {index + 1}
                                    </th>
                                    <td className="tableset">{item.name}</td>
                                    <td className="tableset">
                                      {item.name === "Company Admin" ? (
                                        <>
                                          <Link
                                            to="#"
                                            className="invoice-action-view mr-1 invisible"
                                            onClick={(e) =>
                                              handelDeleteItem(e, item.id)
                                            }
                                          >
                                            <i className="uil-trash iconscolor" />
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          {userPermissions.indexOf(
                                            "role-delete"
                                          ) !== -1 ? (
                                            <Link
                                              to="#"
                                              className="invoice-action-view mr-1"
                                              onClick={(e) =>
                                                handelDeleteItem(e, item.id)
                                              }
                                            >
                                              <i className="uil-trash iconscolor" />
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                      &nbsp;
                                      {userPermissions.indexOf("role-edit") !==
                                      -1 ? (
                                        <Link
                                          to="#"
                                          className="invoice-action-edit cursor-pointer"
                                          onClick={(e) =>
                                            handelEditItem(e, item)
                                          }
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const LeaveEntitlementUser = () => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const compid = DataParser.CompanyId();

  const [entitlementTitle, setEntitlementTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [reaming, setRemainig] = useState("");
  const [used, setUsed] = useState("");
  const [editId, setEditId] = useState("");

  useEffect(() => {
    getLeaveEntitlementsUsers();
  }, []);

  const getLeaveEntitlementsUsers = () => {
    setLoading(true);
    LeaveEntitlementService.viewEntitlemtUsers(id, compid).then((res) => {
      const Data = res.data.leave_entitlements;
      setUsers(Data);
      setLoading(false);
    });
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setEntitlementTitle(item.entitlement);
    setRemainig(item.remainings);
    setUsed(item.used);
    setEditId(item.id);
  };

  const handelSubmit = (e, item) => {
    e.preventDefault();
    const link = document.getElementById("EditCloseLeavePeriod");
    const Data = {
      company_id: compid,
      entitlement: entitlementTitle,
      remainings: reaming,
      used: used,
    };

    LeaveEntitlementService.updateUserEntitlement(editId, Data).then((res) => {
      link.click();
      setEntitlementTitle("");
      setRemainig("");
      setUsed("");
      setEditId("");
      getLeaveEntitlementsUsers();
    });
  };

  return (
    <>
      <Helmet>
        <title>Leaves Entitlement - Planit</title>
      </Helmet>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves Entitlement Users</h4>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <table
                    id="dataEable"
                    className="table mb-0 table-striped"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>#</th>
                        <th>Username</th>
                        <th>Entitlement</th>
                        <th>Used</th>
                        <th>Remainings</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {users &&
                            users.map((el, index) => (
                              <tr
                                role="row"
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td className="tableset">{el.userid}</td>
                                <td className="tableset">{el.username}</td>
                                <td className="tableset">{el.entitlement}</td>
                                <td className="tableset">{el.used}</td>
                                <td className="tableset">{el.remainings}</td>

                                <td className="tableset">
                                  <Link
                                    to="#"
                                    className="cursor-pointer iconscolor"
                                  >
                                    <i
                                      className="uil-pen iconscolor"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editLeavePeriodModal"
                                      onClick={(e) => handleEdit(e, el)}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}

                      <div
                        id="editLeavePeriodModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <form
                          onSubmit={(e) => {
                            handelSubmit(e);
                          }}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="myModalLabel">
                                  Edit User Entitlement
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  id="EditCloseLeavePeriod"
                                  aria-label="Close"
                                />
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Entitlement
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder="Entitlement"
                                        className="form-control"
                                        value={entitlementTitle}
                                        onChange={(e) =>
                                          setEntitlementTitle(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Remamings
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder="Remainings"
                                        className="form-control"
                                        value={reaming}
                                        onChange={(e) =>
                                          setRemainig(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Used
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder="Used"
                                        className="form-control"
                                        value={used}
                                        onChange={(e) =>
                                          setUsed(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  onClick={(e) => {
                                    handelSubmit(e);
                                  }}
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                            {/* /.modal-content modal-dialog-centere */}
                          </div>
                        </form>
                        {/* /.modal-dialog */}
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveEntitlementUser;

        import React from "react";

const Repeater = ({ inputFields, setInputFields }) => {
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <div className="row">
      {inputFields.map((input, index) => {
        return (
          <>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="controls">
                <input
                  type="text"
                  className="form-control inputset"
                  id="email-addr"
                  placeholder="Degrees |Certificates title*"
                  name="degree_title"
                  data-validation-required-message="This  field is required"
                  aria-invalid="true"
                  required
                  value={input.degree_title}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 mb-3">
              <div className="date-picker">
                <input
                  type="date"
                  className="pickadate form-control inputset"
                  value={input.end_date}
                  onChange={(event) => handleFormChange(index, event)}
                  name="end_date"
                  id="pass"
                  data-validation-required-message="This  field is required"
                  data-toggle="tooltip"
                  data-trigger="hover"
                  data-placement="top"
                  data-title="Date Opened"
                  data-original-title=""
                  required
                />
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 d-flex mb-3">
              <input
                type="text"
                className="form-control inputset"
                id="email-addr"
                placeholder="Institue*"
                name="institue"
                data-validation-required-message="This  field is required"
                aria-invalid="true"
                required
                value={input.institue}
                onChange={(event) => handleFormChange(index, event)}
              />
              {inputFields.length === 1 ? null : (
                <button
                  type="button"
                  className=" d-flex justify-content-center align-items-center ml-1 btn"
                  onClick={() => {
                    removeFields();
                  }}
                >
                  <i className="uil-trash-alt" />
                </button>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Repeater;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../Layouts/Sidebar";
import CompanyService from "../../../Services/CompanyService";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import StatusBadge from "../../Reuseable/StatusBadge";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import WorkFromHomeStatus from "../../Reuseable/WorkFromHomeStatus";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Users = () => {
  const navigate = useNavigate();
  const [user, setUsers] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isfulled, setIsFulled] = useState(false);
  const getData = () => {
    setIsLoading(true);
    const companyId = DataParser.CompanyId();
    CompanyService.getComapnyUser(companyId)
      .then((res) => {
        const data = res.data.user;
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          localStorage.removeItem("admins");
          //navigate("/");
        }
        if (res.Good === true) {
          setUsers(data);
          console.log("first: ", DataParser.AllowedCompanyUsers());
          console.log("second: ", data.length);
          if(DataParser.AllowedCompanyUsers() <= data.length ){
            setIsFulled(true)
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          localStorage.removeItem("admins");
          setIsLoading(false);
          // navigate("/");
        }
      });
  };

  useEffect(() => {
    getData();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handelEditItem = (e, item) => {
    e.preventDefault();
    const id = item.id;
    navigate(`/${DataParser.CompanyIdentifierRole()}/users/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>Users - Planit</title>
      </Helmet>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Users</h4>
                  <div className="page-title-right">
                    <ol
                      className="breadcrumb"
                      style={{ marginBottom: "10px !important" }}
                    >
                      <li className="breadcrumb-item">
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/home`}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/adminsettings`}
                        >
                          Settings
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Users</li>
                    </ol>
                    {userPermissions.indexOf("user-create") !== -1 ? (
                      <>
                      {
                        isfulled ? "" : <Link
                        style={{ float: "right" }}
                        to={`/${DataParser.CompanyIdentifierRole()}/users/create`}
                      >
                        &nbsp;
                        <span
                          // to="/packages/create"
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Add
                        </span>
                      </Link>
                      }
                        
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>id</th>
                            <th>UserName</th>
                            <th>Department</th>
                            <th>Name</th>
                            <th>Creation Date</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>WFH</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                              <tr>
                                <th className="tableset" scope="row">
                                  <Skeleton />
                                </th>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                                <td className="tableset">
                                  <Skeleton />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {user &&
                                user.map((item) => (
                                  <tr key={item.id}>
                                    <th className="tableset" scope="row">
                                      {item.id}
                                    </th>
                                    <td className="tableset">{item.email}</td>
                                    <td className="tableset">
                                      <span className="btn btn-success waves-effect waves-light btn-sm">
                                        {item.departments &&
                                          item.departments.name}
                                      </span>
                                    </td>
                                    <td className="tableset">
                                      {item.first_name}&nbsp;
                                      {item.last_name}
                                    </td>
                                    <td className="tableset">
                                      {moment(item.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {" "}
                                      {item.roles && item.roles.name}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf("user-edit") !==
                                        -1 &&
                                      item.roles.name !== "Company Admin" ? (
                                        <StatusBadge
                                          item={item}
                                          api={CompanyService.changeUserStatus}
                                        />
                                      ) : (
                                        <>
                                          {item.status === 1 ? (
                                            <span className="btn btn-success waves-effect waves-light btn-sm">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="btn btn-danger waves-effect waves-light btn-sm">
                                              Deactive
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf("user-edit") !==
                                      -1 ? (
                                        <WorkFromHomeStatus
                                          item={item}
                                          api={
                                            CompanyService.updateStatusWorkFromHome
                                          }
                                        />
                                      ) : (
                                        <>
                                          {item.wrokfrom_home === 1 ? (
                                            <span className="btn btn-success waves-effect waves-light btn-sm">
                                              Yes
                                            </span>
                                          ) : (
                                            <span className="btn btn-danger waves-effect waves-light btn-sm">
                                              No
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf("user-edit") !==
                                      -1 ? (
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            handelEditItem(e, item)
                                          }
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;

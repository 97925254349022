import requests from "./httpService";
const TaskServices = {
  getAllTask(id, body) {
    return requests.get(`/tasks/all?company_id=${id}`, body);
  },
  updateTasksTitle(id, body) {
    return requests.post(`/update-task-title/${id}`, body);
  },
  updateTaskAssigne(id, body) {
    return requests.post(`/tasks/updateasignedto/${id}`, body);
  },
  updateTaskStatus(id, body) {
    return requests.post(`/tasks/updatetaskstatus/${id}`, body);
  },
  createTask(body) {
    return requests.post(`/tasks/create`, body);
  },
  updateTask(id, body) {
    return requests.post(`/tasks/update/${id}`, body);
  },
  viewTask(id, body) {
    return requests.post(`/tasks/view/${id}`, body);
  },
  delTask(id, body) {
    return requests.delete(`/tasks/${id}`, body);
  },
  addTaskNote(id, body) {
    return requests.post(`/tasks/addnote/${id}`, body);
  },
  showTaskNote(id, body) {
    return requests.post(`/tasks/showtasknote/${id}`, body);
  },
  createSubTask(id, body) {
    return requests.post(`/tasks/createsubtask/${id}`, body);
  },
  pinToTop(id, body) {
    return requests.post(`/tasks/updatenotepin/${id}`, body);
  },
  noteUpdate(id, body) {
    return requests.post(`/tasks/updatenote/${id}`, body);
  },
  deleteTask(id, body) {
    return requests.get(`/tasks/${id}`, body);
  },
  deleteTaskNote(id, body) {
    console.log(id, body);
    return requests.post(`/tasks/deletenote/${id}`, body);
  },
  createTaskAttatchment(id, body) {
    return requests.post(`/tasks/attachments${id}`, body);
  },
  updateTaskDueDate(id, body) {
    return requests.post(`/tasks/updatetaskduedate/${id}`, body);
  },
};
export default TaskServices;

import React from "react";
import VacancyService from "../../../../Services/VacancyService";

const ShortListed = ({ applicatns, getData, eidss }) => {
  const handelStatus = (e, item, status) => {
    e.preventDefault();
    VacancyService.updateVacancyApplicantsStatus(item, status).then(
      (res) => {
        console.log("res:", res);
        getData(eidss);
      }
    );
  };
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr className="tableHeaderbg">
                      <th>Applicant's Name</th>
                      <th>Apply for</th>
                      <th>Applied Location</th>
                      <th>Email</th>
                      <th>CV</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicatns.map((es) => (
                      <>
                        {es.applicants
                          .filter((es) => es.status === "shortlisted")
                          .map((ess) => (
                            <tr>
                              <th scope="row">
                                {ess.first_name}
                                {ess.first_name}
                              </th>
                              <td className="tableset">{es.title}</td>
                              <td className="tableset">{ess.location}</td>
                              <td className="tableset">{ess.email}</td>

                              <td className="tableset">
                                <a
                                  target="_blank"
                                  href={`${process.env.REACT_APP_CV_PATH}/${ess.cv}`}
                                >
                                  <i className="uil-eye iconscolor" />
                                </a>
                              </td>
                              <td className="tableset">
                                <button  className="btn btnclr"
                                  onClick={(e) =>
                                    handelStatus(e, ess.id, "interview")
                                  }
                                >
                                  Interview
                                </button>
                              </td>
                            </tr>
                          ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortListed;

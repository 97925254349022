import requests from "./httpService";

const AuthService = {
  loginAdmin(body) {
    return requests.post(`/admin/login`, body);
  },
  forgetPassword(body) {
    return requests.put("/admin/forget-password", body);
  },
  resetPassword(body) {
    return requests.put("/admin/reset-password", body);
  },
};

export default AuthService;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import { toast } from "react-toastify";

const CreateCompanies = () => {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [noOfUsers, setNoOfUsers] = useState("");
  const [packages, setPackage] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrMessage] = useState("");

  const [companyPackagesList, setCompanyPackagesList] = useState([]);

  const GetCompanyPackagesList = () => {
    CompanyService.getCompanyPackages().then((res) => {
      const response = res.data;
      setCompanyPackagesList(response.packages);
    });
  };

  useEffect(() => {
    GetCompanyPackagesList();
  }, []);

  const goNext = (e) => {
    e.preventDefault();
    const link = document.getElementById("navtabs-users");
    link.click();
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const AddCompanyData = {
        company_name: companyName,
        phone: phone,
        no_of_users: noOfUsers,
        package: packages,
        address: address,
        first_name: firstName,
        last_name: lastName,
        email: email,
      };
      CompanyService.createCompany(AddCompanyData)
        .then((res) => {
          if (res.data === "Un-Athunticated request") {
            secureLocalStorage.removeItem("adminInfo");
            secureLocalStorage.removeItem("admins");
            navigate("/");
            setIsSending(false);
          }
          if (res.Good === true) {
            navigate(`/${DataParser.CompanyIdentifierRole()}/companies`);
            setCompanyName("");
            setPhone("");
            setNoOfUsers("");
            setPackage("");
            setAddress("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setIsSending(false);
          }
        })
        .catch((err) => {
          toast.error(err ? err.response.data.message : err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSending(false);
        });
    } catch (err) {}
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active Adminuserindo"
                          data-bs-toggle="tab"
                          href="#navtabs-general"
                          role="tab"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">General</span>
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a
                          className="nav-link Adminuserindo"
                          data-bs-toggle="tab"
                          id="navtabs-users"
                          href="#navtabs-user"
                          role="tab"
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user" />
                          </span>
                          <span className="d-none d-sm-block">
                            Admin User Info
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <form
                      className="needs-validation"
                      noValidate
                      onSubmit={(e) => onSubmitHandler(e)}
                    >
                      <div className="tab-content p-3 text-muted">
                        <div
                          className="tab-pane active"
                          id="navtabs-general"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  Company Title
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                  value={companyName}
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder="Company Title"
                                  required
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Title.
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom02"
                                >
                                  Phone
                                </label>
                                <input
                                  type="tel"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder="Phone"
                                  required
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Phone Number.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  Allowed Users
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  onChange={(e) => setNoOfUsers(e.target.value)}
                                  value={noOfUsers}
                                  id="validationCustom01"
                                  placeholder="Allowed Users"
                                  required
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Title.
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom02"
                                >
                                  Packages
                                </label>
                                <select
                                  className="form-select"
                                  onChange={(e) => setPackage(e.target.value)}
                                  value={packages}
                                  required
                                >
                                  <option>Select</option>
                                  {companyPackagesList &&
                                    companyPackagesList.map((el) => (
                                      <option value={el.id}>{el.name}</option>
                                    ))}
                                </select>
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Packages.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom04"
                                >
                                  Address
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  onChange={(e) => setAddress(e.target.value)}
                                  value={address}
                                  className="form-control"
                                  rows="2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-right">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => goNext(e)}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="navtabs-user"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder="First Name"
                                  required
                                  onChange={(e) => setFirstName(e.target.value)}
                                  value={firstName}
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid First Name.
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom02"
                                >
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder="Last Name"
                                  required
                                  onChange={(e) => setLastName(e.target.value)}
                                  value={lastName}
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Last Name.
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom02"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder="Email"
                                  required
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                <div className="invalid-feedback">
                                  Please Add a valid Email.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-right">
                            <button
                              disabled={isSending}
                              className="btn btn-primary "
                              type="submit"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompanies;

import requests from "./httpService";

const CompanyService = {
  getComapnyUser(id, body) {
    return requests.get(`/user/all?company_id=${id}`, body);
  },
  viewComapnyUser(userId, id, body) {
    return requests.get(`/user/${userId}`, body);
  },
  getCompanyReportsTo(id, body) {
    return requests.post(`/user/reportsTo?company_id=${id}`, body);
  },
  createCompany(body) {
    return requests.post(`/companies/create`, body);
  },
  getPermissions(id, body) {
    return requests.get(`/roles/userPermissions?company_id=${id}`, body);
  },
  getAllComapny(body) {
    return requests.get(`/companies/all`, body);
  },
  getCompanyRoles(body) {
    return requests.get(`/roles/all?company_id=${body}`, body);
  },
  showCompanyRole(id, compid, body) {
    return requests.get(`/roles/${id}?company_id=${compid}`, body);
  },
  deleteCompanyRoles(id) {
    return requests.delete(`/roles/${id}`);
  },
  createCompanyRole(body) {
    return requests.post(`/roles/create`, body);
  },
  updateCompanyRole(id, body) {
    return requests.post(`/roles/update/${id}`, body);
  },
  getCompanyPackages(body) {
    return requests.get(`/packages/all`, body);
  },
  deleteCompanyPackages(id) {
    return requests.delete(`/packages/${id}`);
  },
  createCompanyPackages(body) {
    return requests.post(`/packages/create`, body);
  },
  editCompanyPackages(id, body) {
    return requests.post(`/packages/update/${id}`, body);
  },
  updatePackageStatus(id) {
    return requests.post(`/package-status-update/${id}`);
  },
  updateCompanyData(id, body) {
    return requests.post(`/companies/update/${id}`, body);
  },
  deleteCompany(id) {
    return requests.delete(`/companies/delete/${id}`);
  },
  updateCompanyStatus(id) {
    return requests.post(`/companies/update/${id}`);
  },
  updateCompanyUserStatus(id) {
    return requests.post(`/companies/statusupdate/${id}`);
  },
  createCompanyUser(body, headers) {
    return requests.post(`/user/create`, body, headers);
  },
  createCompanyUserDoc(id, body, headers) {
    return requests.post(`/user/userdocs/${id}`, body, headers);
  },
  getCompanyDepartment(id, body) {
    return requests.get(`/departments/all?company_id=${id}`, body);
  },
  updateCompnayUser(id, body) {
    return requests.post(`/user/update/${id}`, body);
  },
  getCompanyCustomerDetail(id, body) {
    return requests.get(`/customers/${id}`, body);
  },
  getCompanyCustomers(id, body) {
    return requests.get(`/customers/all?company_id=${id}`, body);
  },
  updateStatusWorkFromHome(id, body) {
    return requests.post(`/user/workfromhome/${id}`, body);
  },
  changeUserStatus(id) {
    return requests.post(`/user/statusupdate/${id}`);
  },
};

export default CompanyService;

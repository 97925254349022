import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Sidebar from "../../Layouts/Sidebar";
import PhyicalYearService from "../../../Services/PhyicalYearService";
import DataParser from "../../../utlits/Dataparser";
import { Link } from "react-router-dom";

const AddPhysicalPeriod = () => {
  const dateFormat = "YYYY/MM/DD";
  const companyid = DataParser.CompanyId();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [updateId, setUpdateId] = useState(null);

  const onChange = (date, dateString) => {
    let startDate = moment(date);
    let endDates = moment(startDate, dateFormat).add(364, "days");
    setStartDate(startDate);
    setEndDate(endDates);
    // let dataBody = {
    //   company_id: companyid,
    //   start_date: startDate,
    //   end_date: endDates,
    // };
    // PhyicalYearService.updatePhysicalYear(updateId, dataBody).then((res) => {});
  };

  const getPhysicalPeriodDetails = (c) => {
    PhyicalYearService.getPhysicalYear(c).then((res) => {
      const data = res.data.Settings[0];
      setUpdateId(data.id);
      setStartDate(moment(data.start_date));
      setEndDate(moment(data.end_date));

      if (moment() > moment(data.end_date)) {
        PhyicalYearService.updatePhysicalYear(data.id, 0).then((res) => {
          let dataBody = {
            company_id: companyid,
            start_date: moment(data.end_date).add(1, "day"),
            end_date: moment(data.end_date).add(1, "years"),
          };
          PhyicalYearService.createPhysicalYear(dataBody).then((res) => {
            getPhysicalPeriodDetails(companyid);
          });
        });
      }
    });
  };

  useEffect(() => {
    getPhysicalPeriodDetails(companyid);
  }, []);

  const handelApply = (e) => {
    e.preventDefault();
    alert("Are you sure, this will not be reverted");
    let dataBody = {
      company_id: companyid,
      start_date: moment(startDate).add(1, "years"),
      end_date: moment(endDate).add(1, "years"),
    };
    PhyicalYearService.createPhysicalYear(dataBody).then((res) => {
      getPhysicalPeriodDetails(companyid);
    });
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Company Settings</h4>
                  <div className="page-title-right">
                  <ol
                      className="breadcrumb"
                      style={{ marginBottom: "10px !important" }}
                    >
                      <li className="breadcrumb-item">
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/home`}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/adminsettings`}
                        >
                          Settings
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Company Settings</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label
                        htmlFor="phyicalYear"
                        className="col-md-2 col-form-label"
                      >
                        Fiscal Year
                      </label>
                      <div className="col-md-10">
                        <DatePicker
                          style={{ marginRight: "15px" }}
                          value={startDate}
                          onChange={onChange}
                          picker="month"
                        />
                        <DatePicker value={endDate} picker="month" />
                        <button
                          disabled={updateId}
                          onClick={(e) => handelApply(e)}
                          style={{ marginTop: "0px" }}
                          className="btn btnsss"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhysicalPeriod;

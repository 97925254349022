import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import UserBox from "../../Reuseable/UserBox";
import UserTable from "../../Reuseable/UserTable";

const UserLeaveHr = () => {
  const compId = DataParser.CompanyId();
  const navigate = useNavigate();
  const [Users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [tempUsers, setTempUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [isTable, setIsTable] = useState(true);
  const [isGrid, setIsGrid] = useState(false);

  useEffect(() => {
    getCompanyUsers();
  }, []);

  const getCompanyUsers = () => {
    setLoading(true);
    CompanyService.getComapnyUser(compId).then((res) => {
      const users = res.data.user;
      setUsers(users);
      setTempUsers(users);
      setLoading(false);
    });
  };

  const handelViewUser = (e, item) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/employee_leaveuser`, {
      state: {
        data: item,
      },
    });
  };

  const handelSearch = (e) => {
    setUsername(e.target.value);
    const array = Users.filter((el) =>
      el.first_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setUsers(array);
  };

  const handelReset = (e) => {
    e.preventDefault();
    let newUsers = tempUsers;
    console.log("users: ", newUsers);
    setUsername("");
    setUsers(newUsers);
  };

  const handelCheckAttendance = (e, s) => {
    e.preventDefault();

    const name = `${s.first_name} ${s.last_name}`;

    navigate(`/${DataParser.CompanyIdentifierRole()}/employe-attendance`, {
      state: { data: s.id, name: name },
    });
  };

  const GotoUser = (e, s) => {
    e.preventDefault();
    //alert(s)
    navigate(`/${DataParser.CompanyIdentifierRole()}/employe_leaves`, {
      state: { data: s },
    });
  };

  const EmployeDetails = (e, s) => {
    e.preventDefault();
    //alert(s)
    navigate(`/${DataParser.CompanyIdentifierRole()}/users/${s}`);
  };

  const handelGridTable = (e) => {
    e.preventDefault();
    setIsGrid(!isGrid);
    setIsTable(!isTable);
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div
                style={{ paddingRight: "0px" }}
                className="row align-items-center mb-3"
              >
                <div className="col">
                  <div className=" d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">Users</h4>
                  </div>
                </div>
                <div
                  style={{ paddingRight: "0px" }}
                  className="col-auto  ms-auto"
                >
                  <div className="view-icons">
                    <Link
                      onClick={(e) => {
                        handelGridTable(e);
                      }}
                      to="#"
                      className="grid-view btn btn-link active"
                    >
                      <i className="fa fa-th"></i>
                    </Link>
                    <Link
                      onClick={(e) => {
                        handelGridTable(e);
                      }}
                      to="#"
                      className="list-view2 btn btn-link"
                    >
                      <i className="fa fa-bars"></i>
                    </Link>
                  </div>
                </div>
              </div>
              {isTable ? (
                <>
                  <UserTable
                    EmployeDetails={EmployeDetails}
                    GotoUser={GotoUser}
                    handelCheckAttendance={handelCheckAttendance}
                    handelReset={handelReset}
                    handelSearch={handelSearch}
                    handelViewUser={handelViewUser}
                    isLoading={isLoading}
                    username={username}
                    Users={Users}
                  />
                </>
              ) : null}
              {isGrid ? (
                <>
                  <UserBox
                    EmployeDetails={EmployeDetails}
                    GotoUser={GotoUser}
                    handelCheckAttendance={handelCheckAttendance}
                    handelReset={handelReset}
                    handelSearch={handelSearch}
                    handelViewUser={handelViewUser}
                    isLoading={isLoading}
                    username={username}
                    Users={Users}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLeaveHr;
